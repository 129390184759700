import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useQuery } from '@apollo/client';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles } from '@material-ui/core';

import ModifiersTransferList, { ModifierTransferListItem } from '../../../../../ModifiersTransferList';
import { CategoryReducerAction } from '../..';
import { getDishCategoryModifierAssignmentsQuery, getDishModifiersQuery, getDrinkCategoryModifierAssignmentsQuery, getDrinkModifiersQuery } from './queries';
import {
  deleteDishCategoryModifierAssignment,
  deleteDrinkCategoryModifierAssignment,
  getDishesInCategory,
  getDrinksInCategory,
  insertDishCategoryModifierAssignment,
  insertDishModifierAssignments,
  insertDrinkCategoryModifierAssignment,
  insertDrinkModifierAssignments,
} from './mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  category_type: string;
  classes: ClassNameMap<string>;
  categoryId: string;
  organisationId: string;
  currency: string;
  locale: string;
  dispatch: React.Dispatch<{ type: CategoryReducerAction; value: any }>;
}

interface CategoryModifierAssignment {
  id: string;
  category_id: string;
  modifier_id: string;
  modifier: {
    id: string;
    name: string;
    price: number;
    tax: number;
  };
}

// Extended ModifierTransferListItem with assignment ID
interface ExtendedModifierTransferListItem extends ModifierTransferListItem {
  assignmentId?: string;
}

const StepModifiers = ({ classes, categoryId, organisationId, currency, locale, dispatch, category_type }: Props): React.ReactElement => {
  const [allModifiers, setAllModifiers] = useState<ExtendedModifierTransferListItem[]>([]);
  const [assignedModifiers, setAssignedModifiers] = useState<ExtendedModifierTransferListItem[]>([]);
  const [selectedModifiers, setSelectedModifiers] = useState<ExtendedModifierTransferListItem[]>([]);
  const [categoryType] = useState<string>(category_type);
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showApplyConfirmation, setShowApplyConfirmation] = useState<boolean>(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState<boolean>(false);

  // Queries for modifiers
  const { data: dishModifiersData } = useQuery(getDishModifiersQuery, {
    variables: { organisationId },
    fetchPolicy: 'no-cache',
    skip: categoryType !== 'dish',
  });

  const { data: drinkModifiersData } = useQuery(getDrinkModifiersQuery, {
    variables: { organisationId },
    fetchPolicy: 'no-cache',
    skip: categoryType !== 'drink',
  });

  // Queries for existing category modifier assignments
  const { data: dishCategoryModifiersData, refetch: refetchDishCategoryModifiers } = useQuery(getDishCategoryModifierAssignmentsQuery, {
    variables: { categoryId },
    fetchPolicy: 'no-cache',
    skip: categoryType !== 'dish',
  });

  const { data: drinkCategoryModifiersData, refetch: refetchDrinkCategoryModifiers } = useQuery(getDrinkCategoryModifierAssignmentsQuery, {
    variables: { categoryId },
    fetchPolicy: 'no-cache',
    skip: categoryType !== 'drink',
  });

  // Load available modifiers based on category type and filter for uniqueness
  useEffect(() => {
    if (categoryType === 'dish' && dishModifiersData) {
      const dishModifiers = dishModifiersData.dish_modifiers || [];

      // Filter for unique modifiers based on name, price, and tax
      const uniqueModifiers = filterUniqueModifiers(dishModifiers);

      const mappedModifiers = uniqueModifiers.map((modifier: any) => ({
        id: modifier.id,
        label: modifier.name,
        price: modifier.price,
        tax: modifier.tax,
      }));

      setAllModifiers(mappedModifiers);
    } else if (categoryType === 'drink' && drinkModifiersData) {
      const drinkModifiers = drinkModifiersData.drink_modifiers || [];

      // Filter for unique modifiers based on name, price, and tax
      const uniqueModifiers = filterUniqueModifiers(drinkModifiers);

      const mappedModifiers = uniqueModifiers.map((modifier: any) => ({
        id: modifier.id,
        label: modifier.name,
        price: modifier.price,
        tax: modifier.tax,
      }));

      setAllModifiers(mappedModifiers);
    }
  }, [categoryType, dishModifiersData, drinkModifiersData]);

  // Load existing category modifier assignments
  useEffect(() => {
    if (categoryType === 'dish' && dishCategoryModifiersData) {
      const assignments = dishCategoryModifiersData.dish_category_modifier_assignments || [];
      const mappedAssignments = assignments.map((assignment: CategoryModifierAssignment) => ({
        id: assignment.modifier.id,
        label: assignment.modifier.name,
        price: assignment.modifier.price,
        tax: assignment.modifier.tax,
        assignmentId: assignment.id,
      }));
      setAssignedModifiers(mappedAssignments);
      setSelectedModifiers(mappedAssignments);

      // Map the assigned modifiers to the format expected by the category reducer
      const modifiersForCategory = assignments.map((assignment: CategoryModifierAssignment) => ({
        id: assignment.modifier.id,
        name: assignment.modifier.name,
        price: assignment.modifier.price,
        tax: assignment.modifier.tax,
      }));

      // Dispatch the modifiers to update the category state
      dispatch({ type: CategoryReducerAction.MODIFIERS, value: modifiersForCategory });
    } else if (categoryType === 'drink' && drinkCategoryModifiersData) {
      const assignments = drinkCategoryModifiersData.drink_category_modifier_assignments || [];
      const mappedAssignments = assignments.map((assignment: CategoryModifierAssignment) => ({
        id: assignment.modifier.id,
        label: assignment.modifier.name,
        price: assignment.modifier.price,
        tax: assignment.modifier.tax,
        assignmentId: assignment.id,
      }));
      setAssignedModifiers(mappedAssignments);
      setSelectedModifiers(mappedAssignments);

      // Map the assigned modifiers to the format expected by the category reducer
      const modifiersForCategory = assignments.map((assignment: CategoryModifierAssignment) => ({
        id: assignment.modifier.id,
        name: assignment.modifier.name,
        price: assignment.modifier.price,
        tax: assignment.modifier.tax,
      }));

      // Dispatch the modifiers to update the category state
      dispatch({ type: CategoryReducerAction.MODIFIERS, value: modifiersForCategory });
    }
  }, [categoryType, dishCategoryModifiersData, drinkCategoryModifiersData, dispatch]);

  // Helper function to filter unique modifiers based on name, price, and tax
  const filterUniqueModifiers = (modifiers: any[]) => {
    const uniqueMap = new Map();

    return modifiers.filter((modifier) => {
      // Create a unique key based on name, price, and tax
      const key = `${modifier.name}-${modifier.price}-${modifier.tax}`;

      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, true);
        return true;
      }

      return false;
    });
  };

  // Handle modifier selection in the transfer list
  const handleModifierSelection = (left: ModifierTransferListItem[], right: ModifierTransferListItem[]) => {
    // Convert to ExtendedModifierTransferListItem to maintain type safety
    const rightModifiers = right as ExtendedModifierTransferListItem[];
    setSelectedModifiers(rightModifiers);

    // Map the selected modifiers to the format expected by the category reducer
    const modifiersForCategory = rightModifiers.map((modifier) => ({
      id: modifier.id,
      name: modifier.label,
      price: modifier.price,
      tax: modifier.tax,
    }));

    // Dispatch the modifiers to update the category state
    dispatch({ type: CategoryReducerAction.MODIFIERS, value: modifiersForCategory });
  };

  // Save the selected modifiers to the category
  const saveModifiersToCategory = async () => {
    if (!categoryType) return;

    setIsSaving(true);

    try {
      // Find modifiers to add (in selectedModifiers but not in assignedModifiers)
      const modifiersToAdd = selectedModifiers.filter((item) => !assignedModifiers.some((assigned) => assigned.id === item.id));

      // Find modifiers to remove (in assignedModifiers but not in selectedModifiers)
      const modifiersToRemove = assignedModifiers.filter((assigned) => !selectedModifiers.some((item) => item.id === assigned.id));

      // Add new assignments
      for (const modifier of modifiersToAdd) {
        const assignmentObject = {
          category_id: categoryId,
          modifier_id: modifier.id,
          organisation_id: organisationId,
        };

        if (categoryType === 'dish') {
          await insertDishCategoryModifierAssignment({ object: assignmentObject });
        } else if (categoryType === 'drink') {
          await insertDrinkCategoryModifierAssignment({ object: assignmentObject });
        }
      }

      // Remove assignments
      for (const modifier of modifiersToRemove) {
        if (modifier.assignmentId) {
          if (categoryType === 'dish') {
            await deleteDishCategoryModifierAssignment({ id: modifier.assignmentId });
          } else if (categoryType === 'drink') {
            await deleteDrinkCategoryModifierAssignment({ id: modifier.assignmentId });
          }
        }
      }

      // Refetch the assignments to update the UI
      if (categoryType === 'dish') {
        await refetchDishCategoryModifiers();
      } else if (categoryType === 'drink') {
        await refetchDrinkCategoryModifiers();
      }

      setShowSaveConfirmation(false);
    } catch (error) {
      console.error('Error saving modifiers to category:', error);
    } finally {
      setIsSaving(false);
    }
  };

  // Apply modifiers to all items in the category
  const applyModifiersToAllItems = async () => {
    if (!categoryType || assignedModifiers.length === 0) return;

    setIsApplying(true);
    try {
      // Get all items in the category
      let items: Array<{ id: string; organisation_id: string }> = [];

      if (categoryType === 'dish') {
        const response = await getDishesInCategory({ categoryId });
        items = response.data.dishes;
      } else if (categoryType === 'drink') {
        const response = await getDrinksInCategory({ categoryId });
        items = response.data.drinks;
      }

      // Create modifier assignments for each item
      const modifierAssignments = [];

      for (const item of items) {
        for (const modifier of assignedModifiers) {
          modifierAssignments.push({
            [categoryType === 'dish' ? 'dish_id' : 'drink_id']: item.id,
            modifier: {
              data: {
                name: modifier.label,
                price: modifier.price,
                tax: modifier.tax,
                organisation_id: organisationId,
              },
            },
            organisation_id: organisationId,
          });
        }
      }

      // Insert the modifier assignments
      if (modifierAssignments.length > 0) {
        if (categoryType === 'dish') {
          await insertDishModifierAssignments({ objects: modifierAssignments });
        } else if (categoryType === 'drink') {
          await insertDrinkModifierAssignments({ objects: modifierAssignments });
        }
      }

      setShowApplyConfirmation(false);
    } catch (error) {
      console.error('Error applying modifiers to all items:', error);
    } finally {
      setIsApplying(false);
    }
  };

  return (
    <div className={classes.root}>
      <ModifiersTransferList
        labelLeft="Available modifiers"
        labelRight="Selected modifiers"
        description={`Select modifiers to assign to this ${categoryType || ''} category`}
        allItems={allModifiers}
        currentItems={assignedModifiers}
        handleTransfer={handleModifierSelection}
        currency={currency}
        locale={locale}
      />

      <div className={classes.actions}>
        {/* 
        Button commented out as this functionality is now integrated into the main category save function
        <Button variant="contained" color="primary" onClick={() => setShowSaveConfirmation(true)} disabled={!categoryType} className={classes.saveButton}>
          Save Modifiers to Category
        </Button>
        */}
      </div>

      <div className={classes.applyToAllContainer}>
        <Typography variant="h6" className={classes.applyToAllTitle}>
          Apply to Category Items
        </Typography>
        <Typography variant="body2" className={classes.applyToAllDescription}>
          Selected modifiers will be applied to all items in this category.
        </Typography>
        {/* 
        Button commented out as this functionality is now integrated into the main category save function
        <Button variant="contained" color="primary" onClick={() => setShowApplyConfirmation(true)} disabled={assignedModifiers.length === 0 || !categoryType}>
          Apply Modifiers to All Items
        </Button>
        */}
      </div>

      {/* Save Confirmation Dialog */}
      <Dialog open={showSaveConfirmation} onClose={() => setShowSaveConfirmation(false)}>
        <DialogTitle>Save Modifiers to Category</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body1" gutterBottom>
            This will save the selected modifiers to the category. Are you sure you want to continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSaveConfirmation(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={saveModifiersToCategory} color="primary" variant="contained" disabled={isSaving}>
            {isSaving ? (
              <>
                Saving...
                <CircularProgress size={24} className={classes.buttonProgress} />
              </>
            ) : (
              'Save'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Apply Confirmation Dialog */}
      <Dialog open={showApplyConfirmation} onClose={() => setShowApplyConfirmation(false)}>
        <DialogTitle>Apply Modifiers to All Items</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography variant="body1" gutterBottom>
            This will add the selected modifiers to all items in this category. Are you sure you want to continue?
          </Typography>
          <Typography variant="body2" color="error" gutterBottom>
            Note: This action cannot be undone automatically. You would need to manually remove these modifiers from each item if needed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowApplyConfirmation(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={applyModifiersToAllItems} color="primary" variant="contained" disabled={isApplying}>
            {isApplying ? (
              <>
                Applying...
                <CircularProgress size={24} className={classes.buttonProgress} />
              </>
            ) : (
              'Apply'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(styles)(StepModifiers);
