import { ValidationType } from '../../../../react-app-env';

export interface Modifier {
  id: string;
  name: string;
  price: number;
  tax: number;
}

export interface ModifierAssignment {
  id: string;
  modifier: Modifier;
}

export interface Category {
  name: string;
  colour_id: string;
  active: boolean;
  score: number;
  modifiers?: Modifier[];
}

export enum CategoryType {
  DISH = 'DISH',
  DRINK = 'DRINK',
}

export interface Colour {
  id: string;
  hex: string;
  name: string;
  pattern?: string;
}

export interface ValidationErrors {
  name: ValidationType;
  colour_id: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  colour_id: 'Please provide a colour',
};

export interface Validation {
  name: (name: string) => ValidationType;
  colour_id: (value: string) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
  colour_id: (value) => (!value ? validationErrors.colour_id : false),
};

export enum CategoryReducerAction {
  NAME,
  COLOUR,
  SCORE,
  ACTIVE,
  INIT,
  MODIFIERS,
}

export const categoryReducer = (state: Category, action: { type: CategoryReducerAction; value: any }): Category => {
  switch (action.type) {
    case CategoryReducerAction.NAME:
      return { ...state, name: action.value };
    case CategoryReducerAction.COLOUR:
      return { ...state, colour_id: action.value };
    case CategoryReducerAction.SCORE:
      return { ...state, score: action.value };
    case CategoryReducerAction.ACTIVE:
      return { ...state, active: action.value };
    case CategoryReducerAction.MODIFIERS:
      return { ...state, modifiers: action.value };
    case CategoryReducerAction.INIT:
      const { name, colour_id, active, score, dish_category_modifier_assignments, drink_category_modifier_assignments } = action.value;
      
      // Map modifier assignments to modifiers if they exist
      let modifiers: Modifier[] = [];
      if (dish_category_modifier_assignments && dish_category_modifier_assignments.length > 0) {
        modifiers = dish_category_modifier_assignments.map((assignment: any) => assignment.modifier);
      } else if (drink_category_modifier_assignments && drink_category_modifier_assignments.length > 0) {
        modifiers = drink_category_modifier_assignments.map((assignment: any) => assignment.modifier);
      }
      
      const obj = {
        name,
        colour_id,
        active,
        score,
        modifiers,
      };
      return { ...(obj as Category) };
    default:
      throw new Error();
  }
};
