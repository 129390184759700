import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../../../react-app-env';

const _editDishCategoryItemInitQuery = (id: string) => ({
  locations: [
    {
      name: types.string,
      currency: types.string,
      locale: types.string,
      tax_rate: types.number,
      organisation_id: types.string,
    },
  ],
  dishes_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      category_assignments: [
        {
          category: {
            name: types.string,
            menu: {
              name: types.string,
            },
          },
        },
      ],
    }
  ),
});

type DI = Unpacked<typeof _editDishCategoryItemInitQuery>;
export type Dish = DI['dishes_by_pk'];

export const editDishCategoryItemInitQuery = (id: string) => gql`
  ${query(_editDishCategoryItemInitQuery(id))}
`;

const _editDrinkCategoryItemInitQuery = (id: string) => ({
  locations: [
    {
      name: types.string,
      currency: types.string,
      locale: types.string,
      tax_rate: types.number,
      organisation_id: types.string,
    },
  ],
  drinks_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      category_assignments: [
        {
          category: {
            name: types.string,
            menu: {
              name: types.string,
            },
          },
        },
      ],
    }
  ),
});

type DR = Unpacked<typeof _editDrinkCategoryItemInitQuery>;
export type Location = DR['locations'][0];
export type Drink = DR['drinks_by_pk'];

export const editDrinkCategoryItemInitQuery = (id: string) => gql`
  ${query(_editDrinkCategoryItemInitQuery(id))}
`;

export type CategoryItem = Dish | Drink;

export const editCategoryItemInitQuery = (id: string, categoryType: string) => {
  if (categoryType === 'dish') {
    return editDishCategoryItemInitQuery(id);
  }
  return editDrinkCategoryItemInitQuery(id);
};
