import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Button, Chip, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { InventoryRestockType, Location, LocationMenu, LocationReducerAction, LocationType, validation, ValidationErrors } from '../..';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    sectionDescription: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      marginBottom: theme.spacing(3),
    },
    inputField: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    inlineField: {
      display: 'flex',
      gap: theme.spacing(2),
    },
    helperText: {
      color: '#6B7280',
      marginTop: theme.spacing(1),
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    stickyButtonContainer: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      zIndex: 1000,
    },
    stickyButton: {
      paddingInline: theme.spacing(4),
    },
  });

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  location: Location;
  locationTypes: LocationType[];
  locationMenus: LocationMenu[];
  inventoryRestockTypes: InventoryRestockType[];
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: LocationReducerAction; value: any }>;
  handleSave?: any;
  isLocationCreate: boolean;
}

const General = ({
  classes,
  location,
  locationTypes,
  inventoryRestockTypes,
  locationMenus,
  errors,
  setErrors,
  dispatch,
  handleSave,
  isLocationCreate,
}: Props): React.ReactElement => {
  const [initialState, setInitialState] = useState<Location | null>(null);
  const [stateChanged, setStateChanged] = useState(false);

  useEffect(() => {
    setInitialState(location);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialState && JSON.stringify(initialState) !== JSON.stringify(location)) {
      setStateChanged(true);
    } else {
      setStateChanged(false);
    }
  }, [location, initialState]);
  const validate = useCallback(
    (field: string) => {
      const errorItems: { [key: string]: string } = {};
      if (field === 'name') {
        errorItems.name = validation.name(location.name) as string;
      }
      if (field === 'email') {
        errorItems.email = validation.email(location.email) as string;
      }
      if (field === 'phone') {
        errorItems.phone = validation.phone(location.phone) as string;
      }
      if (field === 'location_type') {
        errorItems.location_type = validation.location_type(location.location_type) as string;
      }
      if (field === 'address_line_1') {
        errorItems.address_line_1 = validation.address_line_1(location.address_line_1) as string;
      }
      if (field === 'address_city') {
        errorItems.address_city = validation.address_city(location.address_city) as string;
      }
      if (field === 'address_country') {
        errorItems.address_country = validation.address_country(location.address_country) as string;
      }
      if (field === 'address_postcode') {
        errorItems.address_postcode = validation.address_postcode(location.address_postcode) as string;
      }
      if (field === 'locale') {
        errorItems.locale = validation.locale(location.locale) as string;
      }
      if (field === 'stock_type') {
        errorItems.locale = validation.stock_type(location.inventory_restock_type) as string;
      }
      setErrors({ ...errors, ...errorItems });
    },
    [location, errors, setErrors]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h6" className={classes.sectionTitle}>
          General
        </Typography>
      </Grid>

      {/* Store Name Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Store name
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Customer-facing and internal names for your store.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <TextField
            label="Public name"
            variant="outlined"
            required
            className={classes.inputField}
            value={location.name}
            error={errors.name as boolean}
            helperText={errors.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.NAME,
                value: event.target.value,
              })
            }
            onBlur={() => validate('name')}
            onKeyUp={() => validate('name')}
            FormHelperTextProps={{ className: classes.helperText }}
          />
        </Paper>
      </Grid>

      {/* Email Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Email
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            The email address for contacting your store.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <TextField
            className={classes.inputField}
            required
            label="Contact email address"
            variant="outlined"
            error={errors.email as boolean}
            helperText={errors.email}
            value={location.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.EMAIL,
                value: event.target.value,
              })
            }
            onBlur={() => validate('email')}
            onKeyUp={() => validate('email')}
            FormHelperTextProps={{ className: classes.helperText }}
          />
        </Paper>
      </Grid>

      {/* Location Type Section */}
      {/*<Grid item xs={12} sm={4}>*/}
      {/*  <Box>*/}
      {/*    <Typography variant="subtitle1" className={classes.sectionTitle}>*/}
      {/*      Location Type*/}
      {/*    </Typography>*/}
      {/*    <Typography variant="body2" className={classes.sectionDescription}>*/}
      {/*      Specify the type of location (e.g., physical store, warehouse).*/}
      {/*    </Typography>*/}
      {/*  </Box>*/}
      {/*</Grid>*/}
      {/*<Grid item xs={12} sm={8}>*/}
      {/*  <Paper className={classes.paper}>*/}
      {/*    <FormControl variant="outlined" required className={classes.inputField} error={errors.location_type as boolean}>*/}
      {/*      <InputLabel id="location_type-select-label">Location type</InputLabel>*/}
      {/*      <Select*/}
      {/*        labelId="location_type-select-label"*/}
      {/*        label="Location type"*/}
      {/*        autoWidth*/}
      {/*        value={location.location_type}*/}
      {/*        onKeyUp={() => validate('location_type')}*/}
      {/*        onBlur={() => validate('location_type')}*/}
      {/*        onChange={(event: React.ChangeEvent<any>) =>*/}
      {/*          dispatch({*/}
      {/*            type: LocationReducerAction.LOCATION_TYPE,*/}
      {/*            value: event.target.value,*/}
      {/*          })*/}
      {/*        }>*/}
      {/*        {locationTypes.map((i: LocationType) => (*/}
      {/*          <MenuItem key={i.location_type} value={i.location_type}>*/}
      {/*            {i.description}*/}
      {/*          </MenuItem>*/}
      {/*        ))}*/}
      {/*      </Select>*/}
      {/*      <FormHelperText className={classes.helperText}>{errors.location_type}</FormHelperText>*/}
      {/*    </FormControl>*/}
      {/*  </Paper>*/}
      {/*</Grid>*/}

      {/* Address Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Address
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            The physical location details of your store.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <TextField
            label="Address line 1"
            variant="outlined"
            required
            className={classes.inputField}
            value={location.address_line_1}
            error={errors.address_line_1 as boolean}
            helperText={errors.address_line_1}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.ADDRESS_LINE_1,
                value: event.target.value,
              })
            }
            onBlur={() => validate('address_line_1')}
            onKeyUp={() => validate('address_line_1')}
            FormHelperTextProps={{ className: classes.helperText }}
          />
          <TextField
            label="Address line 2"
            variant="outlined"
            className={classes.inputField}
            value={location.address_line_2}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.ADDRESS_LINE_2,
                value: event.target.value,
              })
            }
            onBlur={() => validate('address_line_2')}
            onKeyUp={() => validate('address_line_2')}
            FormHelperTextProps={{ className: classes.helperText }}
          />
          <TextField
            label="Town or city"
            variant="outlined"
            required
            className={classes.inputField}
            value={location.address_city}
            error={errors.address_city as boolean}
            helperText={errors.address_city}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.ADDRESS_CITY,
                value: event.target.value,
              })
            }
            onBlur={() => validate('address_city')}
            onKeyUp={() => validate('address_city')}
            FormHelperTextProps={{ className: classes.helperText }}
          />
          <TextField
            label="Postcode or ZIP code"
            variant="outlined"
            required
            className={classes.inputField}
            value={location.address_postcode}
            error={errors.address_postcode as boolean}
            helperText={errors.address_postcode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.ADDRESS_POSTCODE,
                value: event.target.value,
              })
            }
            onBlur={() => validate('address_postcode')}
            onKeyUp={() => validate('address_postcode')}
            FormHelperTextProps={{ className: classes.helperText }}
          />
          <TextField
            label="Address country"
            variant="outlined"
            data-qa="address_country-textfield"
            required
            className={classes.inputField}
            value={location.address_country}
            error={errors.address_country as boolean}
            helperText={errors.address_country}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.ADDRESS_COUNTRY,
                value: event.target.value,
              })
            }
            onBlur={() => validate('address_country')}
            onKeyUp={() => validate('address_country')}
            FormHelperTextProps={{ className: classes.helperText }}
          />

          <TextField
            className={classes.inputField}
            data-qa="phone-textfield"
            required
            label="Contact phone number"
            variant="outlined"
            error={errors.phone as boolean}
            helperText={errors.phone}
            value={location.phone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.PHONE,
                value: event.target.value,
              })
            }
            onKeyUp={() => validate('phone')}
            onBlur={() => validate('phone')}
          />

          {/*<Box display="flex" alignItems="center">*/}
          {/*  <Typography variant="body2" className={classes.helperText}>*/}
          {/*    Visible to public*/}
          {/*  </Typography>*/}
          {/*  <Switch color="primary" />*/}
          {/*</Box>*/}
        </Paper>
      </Grid>

      {/* Website Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Website
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Your store’s online presence to make it easier for customers to find you online.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <TextField
            label="URL address"
            variant="outlined"
            // required
            className={classes.inputField}
            value={location.website}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: LocationReducerAction.WEBSITE,
                value: event.target.value,
              })
            }
            InputProps={{
              endAdornment: (
                <Box>
                  <Tooltip title="Open to test link" arrow>
                    <IconButton aria-label="open link" onClick={() => window.open(location.website, '_blank')}>
                      <OpenInNewIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ),
            }}
          />
        </Paper>
      </Grid>
      {/* Location Menu Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Location menu
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Select the location menu for this store.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <FormControl variant="outlined" className={classes.inputField}>
            <InputLabel id="location-menu-select-label">Location menu</InputLabel>
            <Select
              labelId="location-menu-select-label"
              label="Location menu"
              fullWidth
              value={location.location_menu_id}
              onChange={(event: React.ChangeEvent<any>) =>
                dispatch({
                  type: LocationReducerAction.SET_LOCATION_MENU,
                  value: event.target.value,
                })
              }>
              {locationMenus.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  <Grid container justify="space-between">
                    <Grid item xs={8}>
                      {i.name}
                    </Grid>
                    {i.is_primary && (
                      <Grid item>
                        <Chip label="Primary" />
                      </Grid>
                    )}
                  </Grid>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
      </Grid>

      {/* Locale Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Locale
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Select the primary language used for AccentPOS Hub.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <FormControl variant="outlined" className={classes.inputField} required error={errors.locale as boolean}>
            <InputLabel id="locale-select-label">Locale</InputLabel>
            <Select
              labelId="locale-select-label"
              label="Locale"
              value={location.locale}
              onChange={(event: React.ChangeEvent<any>) =>
                dispatch({
                  type: LocationReducerAction.LOCALE,
                  value: event.target.value,
                })
              }
              onBlur={() => validate('locale')}
              onKeyUp={() => validate('locale')}>
              {['en-GB', 'en-US'].map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.helperText}>{errors.locale}</FormHelperText>
          </FormControl>
        </Paper>
      </Grid>

      {/* Currency Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Inventory Restock Type
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Set the restock type for the menu
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <FormControl variant="outlined" required className={classes.inputField} error={errors.stock_type as boolean}>
            <InputLabel id="inventory-type-label">Inventory Restock Type</InputLabel>
            <Select
              labelId="inventory-type-label"
              label="Inventory Restock Type"
              value={location.inventory_restock_type}
              onBlur={() => validate('stock_type')}
              onKeyUp={() => validate('stock_type')}
              onChange={(e) =>
                dispatch({
                  type: LocationReducerAction.SET_INVENTORY_RESTOCK_TYPE,
                  value: e.target.value,
                })
              }>
              {inventoryRestockTypes.map((i: InventoryRestockType) => (
                <MenuItem key={i.inventory_restock_type} value={i.inventory_restock_type}>
                  {i.description}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classes.helperText}>{errors.stock_type}</FormHelperText>
          </FormControl>
        </Paper>
      </Grid>
      {stateChanged && !isLocationCreate && (
        <Box className={classes.stickyButtonContainer}>
          <Button variant="contained" color="primary" className={classes.stickyButton} onClick={handleSave}>
            Save changes
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default withStyles(styles)(General);
