import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    chip: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: theme.spacing(1, 2),
    },
    chipLabel: {
      marginTop: theme.spacing(0.5),
      color: theme.palette.text.secondary,
    },
    modifiersTitle: {
      padding: theme.spacing(1, 2),
      fontWeight: 500,
    },
    modifiersSection: {
      marginTop: theme.spacing(2),
    },
  });

export default styles;
