import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import { Grid, withStyles, Chip, Typography, Tooltip } from '@material-ui/core';
import { Help as InfoIcon } from '@material-ui/icons';

import ReadOnlyBlock from '../../../../components/ReadOnlyBlock';
import { booleanToYesNo, numberToMoney } from '../../../../utils/stringUtils';
import { createPriceTaxString } from '../../../../utils/conversions';
import ColourPickerItem from '../../../../components/Global/ColourPicker/item';
import ListCategoryItems from '../../../../components/Global/CategoryItem/List';

import { Organisation, Category, CategoryItem, CategoryItemAssignment } from '../Common/types';

import styles from './styles';
import { selectCategoryInitQuery } from './queries';

interface CategoryModifier {
  id: string;
  name: string;
  price: number;
  tax: number;
}

interface CategoryModifierAssignment {
  modifier: CategoryModifier;
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  organisation_id?: string;
  category_id: string;
  category_type: 'dish' | 'drink';
  handleEdit?: () => void;
  handleEditItem?: (id: string) => void;
  handleCreateItem?: () => void;
}

const ViewCategory = ({ classes, organisation_id, category_id, category_type, handleEdit, handleEditItem, handleCreateItem, history }: Props): React.ReactElement => {
  const [category, setCategory] = useState<Category>({
    id: '',
    name: '',
    colour_id: '',
    active: false,
    score: 0,
  });
  const [organisation, setOrganisation] = useState<Organisation>({
    id: '',
    name: '',
    currency: '',
    locale: '',
  });
  const [items, setItems] = useState<CategoryItem[]>([]);
  const [modifiers, setModifiers] = useState<CategoryModifier[]>([]);

  const { data: categoryInitData, refetch } = useQuery(selectCategoryInitQuery(category_id, category_type), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && categoryInitData) {
      const cat = categoryInitData.categories_by_pk;
      setCategory(cat);
      setOrganisation(cat.organisation);
      const itms = cat[`${category_type}_assignments`].map((i: CategoryItemAssignment) => i[category_type]);
      setItems(itms.sort((a: CategoryItem, b: CategoryItem) => a.order_index - b.order_index));

      // Extract modifiers from category
      const categoryModifiers = cat[`${category_type}_category_modifier_assignments`] || [];
      setModifiers(categoryModifiers.map((assignment: CategoryModifierAssignment) => assignment.modifier));
    }
    return () => {
      mounted = false;
    };
  }, [categoryInitData, category_type]);

  const handleItemScoreUpdate = () => {
    refetch();
  };

  const renderModifierChips = () => {
    if (modifiers.length === 0) {
      return (
        <div className={classes.chips}>
          <Typography className={classes.chipLabel} variant="subtitle2">
            No modifiers
          </Typography>
        </div>
      );
    }
    return (
      <div className={classes.chips}>
        {modifiers.map((modifier: CategoryModifier) => {
          const { name, price, tax } = modifier;
          const mPrice = numberToMoney(price, organisation.currency, organisation.locale, true);
          const mTax = createPriceTaxString(price, tax, organisation.currency, organisation.locale, true);
          return (
            <Tooltip key={modifier.id} title={`${mPrice} ~ ${tax / 10}% tax = ${mTax}`} arrow placement="top">
              <Chip className={classes.chip} deleteIcon={<InfoIcon />} size="small" variant="outlined" label={name} />
            </Tooltip>
          );
        })}
      </div>
    );
  };

  return (
    <Grid container spacing={4} alignItems="stretch">
      <Grid item xs={12}>
        <ReadOnlyBlock
          title={category.name}
          gridSize={3}
          items={[
            { label: 'Active', value: booleanToYesNo(category.active) },
            {
              label: 'Colour',
              components: [<ColourPickerItem key="colour_picker" small colour_id={category.colour_id} />],
            },
            { label: 'Score', value: category.score?.toString() },
            { label: 'Modifiers', components: [renderModifierChips()] },
          ]}
          handleEdit={handleEdit}
        />
      </Grid>
      <Grid item xs={12}>
        <ListCategoryItems
          organisation_id={organisation_id}
          category_type={category_type}
          currency={organisation.currency}
          locale={organisation.locale}
          items={items}
          handleSetItems={setItems}
          handleEditItem={handleEditItem}
          handleCreateItem={handleCreateItem}
          handleItemScoreUpdate={handleItemScoreUpdate}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(withStyles(styles)(ViewCategory));
