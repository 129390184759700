import React, { useCallback, useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { UnknownObject } from '../../../../../../react-app-env';
import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import CustomStepper from '../../../../../CustomStepper';
import { Category, validationErrors, ValidationErrors, CategoryReducerAction, validation } from '../..';
import StepDetails from '../StepDetails';
import StepModifiers from '../StepModifiers';
import StepReview from '../StepReview';
import { getDishCategoryModifierAssignmentsQuery, getDrinkCategoryModifierAssignmentsQuery } from '../StepModifiers/queries';

import styles from './styles';

interface CategoryModifier {
  id: string;
  name: string;
  price: number;
  tax: number;
}

interface CategoryModifierAssignment {
  id: string;
  category_id: string;
  modifier_id: string;
  modifier: CategoryModifier;
}

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  completeLabel: string;
  category: Category;
  category_type: string;
  category_id?: string;
  organisation_id?: string;
  currency?: string;
  locale?: string;
  dispatch: React.Dispatch<{ type: CategoryReducerAction; value: any }>;
  handleSave: () => void;
  handleAnother?: () => void;
}

const CategorySteps = ({
  classes,
  completeLabel,
  category,
  category_id,
  category_type,
  organisation_id,
  currency,
  locale,
  dispatch,
  handleSave,
  handleAnother,
}: Props): React.ReactElement => {
  const [step, setStep] = useState<number>(0);
  const [categoryModifiers, setCategoryModifiers] = useState<CategoryModifier[]>([]);

  const [errors, setErrors] = useState<ValidationErrors>({
    name: false,
    colour_id: false,
  });

  // Queries for existing category modifier assignments
  const { data: dishCategoryModifiersData } = useQuery(getDishCategoryModifierAssignmentsQuery, {
    variables: { categoryId: category_id },
    fetchPolicy: 'no-cache',
    skip: !category_id || category_type !== 'dish',
  });

  const { data: drinkCategoryModifiersData } = useQuery(getDrinkCategoryModifierAssignmentsQuery, {
    variables: { categoryId: category_id },
    fetchPolicy: 'no-cache',
    skip: !category_id || category_type !== 'drink',
  });

  // Load category modifiers when data is available
  useEffect(() => {
    if (category_type === 'dish' && dishCategoryModifiersData) {
      const assignments = dishCategoryModifiersData.dish_category_modifier_assignments || [];
      const modifiers = assignments.map((assignment: CategoryModifierAssignment) => assignment.modifier);
      setCategoryModifiers(modifiers);
    } else if (category_type === 'drink' && drinkCategoryModifiersData) {
      const assignments = drinkCategoryModifiersData.drink_category_modifier_assignments || [];
      const modifiers = assignments.map((assignment: CategoryModifierAssignment) => assignment.modifier);
      setCategoryModifiers(modifiers);
    }
  }, [category_type, dishCategoryModifiersData, drinkCategoryModifiersData]);

  const validate = useCallback(
    (_step: number) => {
      const errorItems: UnknownObject = {};
      if (_step === 0) {
        errorItems.name = validation.name(category.name);
        errorItems.colour_id = validation.colour_id(category.colour_id);
      }
      setErrors({ ...errors, ...errorItems });
      return (Object.keys(errorItems) as Array<keyof typeof validationErrors>).some((i) => errorItems[i]);
    },
    [category, errors]
  );

  return (
    <>
      <CustomStepper
        step={step}
        isNextDisabled={(Object.keys(errors) as Array<keyof typeof errors>).some((i) => errors[i])}
        completeLabel={completeLabel}
        setStep={setStep}
        validate={validate}
        handleComplete={handleSave}
        handleAnother={handleAnother}>
        {[
          {
            label: 'Details',
            component: <StepDetails category={category} errors={errors} setErrors={setErrors} dispatch={dispatch} />,
          },
          {
            label: 'Modifiers',
            gridSize: 7,
            component: (
              <StepModifiers
                categoryId={category_id!}
                category_type={category_type}
                organisationId={organisation_id!}
                currency={currency || 'USD'}
                locale={locale || 'en-US'}
                dispatch={dispatch}
              />
            ),
          },
          {
            label: 'Summary',
            gridSize: 6,
            component: (
              <StepReview
                category={category}
                category_type={category_type}
                modifiers={category.modifiers}
                currency={currency || 'USD'}
                locale={locale || 'en-US'}
                setStep={setStep}
              />
            ),
          },
        ]}
      </CustomStepper>
    </>
  );
};

export default withStyles(styles)(CategorySteps);
