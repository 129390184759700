import React, { useState, useEffect } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { pluralise } from '../../utils/stringUtils';
import { getCurrencySymbol, numberToMoney } from '../../utils/stringUtils';

import { Button, Checkbox, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, withStyles, Typography, InputBase } from '@material-ui/core';

import { ChevronLeft as MoveLeftIcon, ChevronRight as MoveRightIcon, FirstPage as MoveAllLeftIcon, LastPage as MoveAllRightIcon } from '@material-ui/icons';

import { Search as SearchIcon, Clear as ClearSearchIcon } from '@material-ui/icons/';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  labelLeft: string;
  labelRight: string;
  description?: string;
  currentItems: ModifierTransferListItem[];
  allItems: ModifierTransferListItem[];
  availableItems?: ModifierTransferListItem[]; 
  handleTransfer: (left: ModifierTransferListItem[], right: ModifierTransferListItem[]) => void;
  currency: string;
  locale: string;
  preselectedCategoryModifiers?: boolean; 
}

export interface ModifierTransferListItem {
  id: string;
  label: string;
  price: number;
  tax: number;
  isCategoryModifier?: boolean;
  assignmentId?: string;
}

const not = (a: ModifierTransferListItem[], b: ModifierTransferListItem[]) => a.filter((value) => b.indexOf(value) === -1);

const intersection = (a: ModifierTransferListItem[], b: ModifierTransferListItem[]) => a.filter((value) => b.indexOf(value) !== -1);

const ModifiersTransferList = ({ 
  classes, 
  labelLeft, 
  labelRight, 
  description, 
  currentItems, 
  allItems, 
  availableItems,
  handleTransfer, 
  currency, 
  locale,
  preselectedCategoryModifiers
}: Props): React.ReactElement => {
  const [checked, setChecked] = useState<ModifierTransferListItem[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [left, setLeft] = useState<ModifierTransferListItem[]>([]);
  const [right, setRight] = useState<ModifierTransferListItem[]>(currentItems);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  
  // Update left and right lists when allItems or currentItems change
  useEffect(() => {
    if (availableItems) {
      setLeft(availableItems);
      setRight(currentItems);
    } else if (allItems && allItems.length > 0) {
      const availableItems = allItems.filter((item) => !currentItems.some((current) => item.label === current.label && item.price === current.price && item.tax === current.tax));
      setLeft(availableItems);
      setRight(currentItems);
    }
  }, [allItems, currentItems, availableItems]);
  
  useEffect(() => {
    let mounted = true;
    const rightIds = right.map((i) => i.id);
    const currentIds = currentItems.map((i) => i.id);
    const areSame = currentIds.every((id) => rightIds.includes(id)) && rightIds.every((id) => currentIds.includes(id));
    if (mounted && !areSame) {
      handleTransfer(left, right);
    }
    return () => {
      mounted = false;
    };
  }, [right]);

  const handleToggle = (value: ModifierTransferListItem) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    if (searchValue.length > 0) {
      setRight((i) => [...i, ...left.filter((i) => i.label.toLowerCase().includes(searchValue.toLowerCase()))]);
      setLeft(left.filter((i) => !i.label.toLowerCase().includes(searchValue.toLowerCase())));
    } else {
      setRight((i) => [...i, ...left]);
      setLeft([]);
    }
    setChecked([]);
  };

  const handleCheckedRight = () => {
    setRight((i) => [...i, ...leftChecked]);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft((i) => [...i, ...rightChecked]);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft((i) => [...i, ...right]);
    setRight([]);
    setChecked([]);
  };

  const customList = (title: string, items: ModifierTransferListItem[]) => {
    const sortedItems = [...items].sort((a, b) => {
      if ((a.isCategoryModifier && b.isCategoryModifier) || (!a.isCategoryModifier && !b.isCategoryModifier)) {
        return a.label.localeCompare(b.label);
      }
      return a.isCategoryModifier ? -1 : 1;
    });

    return (
      <Paper className={classes.paper} elevation={0} variant="outlined">
        <Typography className={classes.listTitle} variant="subtitle1">
          {items.length} {title}
        </Typography>
        <Divider />
        <List dense component="div" role="list">
          {sortedItems.map((item) => {
            const labelId = `transfer-list-item-${item.id}-label`;
            return (
              <ListItem 
                key={item.id} 
                role="listitem" 
                button 
                onClick={handleToggle(item)}
                className={item.isCategoryModifier ? classes.categoryModifierItem : undefined}
              >
                <ListItemIcon>
                  <Checkbox
                    data-qa="transfer-list-item-check-box"
                    checked={checked.indexOf(item) !== -1}
                    color="primary"
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  data-qa="transfer-list-item-name"
                  id={labelId}
                  primary={item.label}
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2" color="textPrimary">
                        {`${getCurrencySymbol(currency, locale)}${(item.price / 100).toFixed(2)}`}
                      </Typography>
                      <Typography component="span" variant="body2" color="textSecondary">
                        {` • Tax: ${(item.tax / 10).toFixed(1)}%`}
                      </Typography>
                      {item.isCategoryModifier && (
                        <Typography component="div" variant="body2" color="primary" className={classes.categoryModifierLabel}>
                          category modifier
                        </Typography>
                      )}
                    </React.Fragment>
                  }
                />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" className={classes.root}>
        <Grid item xs={5}>
          <Paper variant="outlined" className={classes.search}>
            <InputBase
              className={classes.searchInput}
              placeholder="Search"
              value={searchValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value || '')}
              startAdornment={<SearchIcon className={classes.searchButton} />}
              endAdornment={<ClearSearchIcon className={classes.searchButton} onClick={() => setSearchValue('')} />}
              inputProps={{ 'aria-label': 'search list' }}
            />
          </Paper>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <Typography className={classes.description} variant="caption">
            {description}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" className={classes.root}>
        <Grid item xs={5} className={classes.list}>
          {customList(
            labelLeft,
            left.filter((i) => i.label.toLowerCase().includes(searchValue.toLowerCase()))
          )}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Button
              data-qa="movel-all-right-button"
              variant="outlined"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right">
              <MoveAllRightIcon />
            </Button>
            <Button
              data-qa="move-selected-right-button"
              variant="outlined"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right">
              <MoveRightIcon />
            </Button>
            <Button
              data-qa="move-selected-left-button"
              variant="outlined"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left">
              <MoveLeftIcon />
            </Button>
            <Button
              data-qa="move-all-left-button"
              variant="outlined"
              size="small"
              color="primary"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left">
              <MoveAllLeftIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5} className={classes.list}>
          {customList(labelRight, right)}
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles)(ModifiersTransferList);
