import React, { useReducer, useState, useContext } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { Allergy, categoryItemReducer, CategoryItemReducerAction, Modifier } from '../Common';
import Steps from '../Common/components/Steps';
import { AuthContext, EnumUserRole } from '../../../../components/AuthProvider';

import { createDish, createDrink } from './mutations';
import { getCategoryDishNextOrderIndex, getCategoryDrinkNextOrderIndex } from './queries';

import styles from './styles';
import { webhookClient } from '../../../../utils/webhookClient';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  category_id: string;
  category_type: string;
  currency: string;
  locale: string;
  taxRate: number;
  organisation_id?: string;
  handleFinish: (id: string) => void;
}

const CreateCategoryItem = ({ classes, history, category_id, category_type, currency, locale, taxRate, organisation_id, handleFinish }: Props): React.ReactElement => {
  const { userRole } = useContext(AuthContext)!;
  const isAdmin = userRole === EnumUserRole.ADMIN;

  const defaultData = {
    name: '',
    description: '',
    active: true,
    tag: '',
    price: 0,
    tax: taxRate || 0,
    prep_time: 0,
    bypass_prep: true,
    should_print: true,
    auto_serve_when_ready: false,
    modifiers: [],
    allergies: [],
    image_added: false,
    score: 0,
  };
  const [categoryItem, dispatchCategoryItem] = useReducer(categoryItemReducer, { ...defaultData });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async (another: boolean) => {
    setSaving(true);

    let order_index: number = 0;
    if (category_type === 'dish') {
      order_index = await getCategoryDishNextOrderIndex(category_id);
    }
    if (category_type === 'drink') {
      order_index = await getCategoryDrinkNextOrderIndex(category_id);
    }

    const { imageData, croppedImageData, ...rest } = categoryItem;

    const item: UnknownObject = {
      ...rest,
      image_added: !!croppedImageData,
    };

    delete item.modifiers;
    delete item.allergies;

    const data: UnknownObject = {
      ...item,
      order_index: order_index + 1,
      category_assignments: {
        data: {
          category_id,
        },
      },
      modifier_assignments: {
        data: rest.modifiers.map((i: Modifier) => ({
          modifier: {
            data: {
              name: i.name,
              price: i.price,
              tax: i.tax,
            },
          },
        })),
      },
      allergy_assignments: {
        data: rest.allergies.map((i: Allergy) => ({
          allergy_id: i.id,
        })),
      },
    };


    if (isAdmin && organisation_id) {
      data.organisation_id = organisation_id;
      data.category_assignments.data.organisation_id = organisation_id;

      data.modifier_assignments.data = data.modifier_assignments.data.map((item: UnknownObject) => {
        const modifierData = { ...item };
        modifierData.organisation_id = organisation_id;
        modifierData.modifier.data.organisation_id = organisation_id;
        return modifierData;
      });

      data.allergy_assignments.data = data.allergy_assignments.data.map((item: UnknownObject) => {
        return { ...item, organisation_id };
      });
    }

    const insertCategoryItemVariables = {
      object: data,
    };

    let id: string | undefined;

    if (category_type === 'dish') {
      try {
        id = await createDish(insertCategoryItemVariables);
      } catch (error) {
        if (error?.message?.includes('organisation_id')) {
          const { organisation_id: orgId, ...dataWithoutOrgId } = data;

          const { organisation_id: catOrgId, ...categoryWithoutOrgId } = data.category_assignments.data;
          const newCategoryAssignments = { data: categoryWithoutOrgId };

          const newAllergyAssignments = {
            data: data.allergy_assignments.data.map((item: UnknownObject) => {
              const { organisation_id: allergyOrgId, ...allergyWithoutOrgId } = item;
              return allergyWithoutOrgId;
            }),
          };

          const newModifierAssignments = {
            data: data.modifier_assignments.data.map((item: UnknownObject) => {
              const { organisation_id: modOrgId, ...modifierWithoutOrgId } = item;

              if (modifierWithoutOrgId.modifier && modifierWithoutOrgId.modifier.data) {
                const { organisation_id: modDataOrgId, ...modifierDataWithoutOrgId } = modifierWithoutOrgId.modifier.data;
                return {
                  ...modifierWithoutOrgId,
                  modifier: {
                    data: modifierDataWithoutOrgId,
                  },
                };
              }

              return modifierWithoutOrgId;
            }),
          };

          id = await createDish({
            object: {
              ...dataWithoutOrgId,
              category_assignments: newCategoryAssignments,
              modifier_assignments: newModifierAssignments,
              allergy_assignments: newAllergyAssignments,
            },
          });
        } else {
          throw error;
        }
      }
    }
    if (category_type === 'drink') {
      try {
        id = await createDrink(insertCategoryItemVariables);
      } catch (error) {
        if (error?.message?.includes('organisation_id')) {
          const { organisation_id: orgId, ...dataWithoutOrgId } = data;

          const { organisation_id: catOrgId, ...categoryWithoutOrgId } = data.category_assignments.data;
          const newCategoryAssignments = { data: categoryWithoutOrgId };

          const newAllergyAssignments = {
            data: data.allergy_assignments.data.map((item: UnknownObject) => {
              const { organisation_id: allergyOrgId, ...allergyWithoutOrgId } = item;
              return allergyWithoutOrgId;
            }),
          };

          const newModifierAssignments = {
            data: data.modifier_assignments.data.map((item: UnknownObject) => {
              const { organisation_id: modOrgId, ...modifierWithoutOrgId } = item;

              if (modifierWithoutOrgId.modifier && modifierWithoutOrgId.modifier.data) {
                const { organisation_id: modDataOrgId, ...modifierDataWithoutOrgId } = modifierWithoutOrgId.modifier.data;
                return {
                  ...modifierWithoutOrgId,
                  modifier: {
                    data: modifierDataWithoutOrgId,
                  },
                };
              }

              return modifierWithoutOrgId;
            }),
          };

          id = await createDrink({
            object: {
              ...dataWithoutOrgId,
              category_assignments: newCategoryAssignments,
              modifier_assignments: newModifierAssignments,
              allergy_assignments: newAllergyAssignments,
            },
          });
        } else {
          throw error;
        }
      }
    }
    if (id) {
      if (croppedImageData) {
        const request = new FormData();
        const imageFile = await fetch(croppedImageData)
          .then((res) => res.blob())
          .then((blob) => {
            return new File([blob], `${id}.jpeg`, { type: 'image/jpeg' });
          });
        request.append('file', imageFile);
        await webhookClient.postFormData('menu/images-upload', request);
      }

      setSaving(false);
      if (another) {
        dispatchCategoryItem({ type: CategoryItemReducerAction.INIT, value: defaultData });
      } else {
        handleFinish(id);
      }
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        categoryItem={categoryItem}
        organisation_id={organisation_id!}
        currency={currency}
        locale={locale}
        taxRate={taxRate}
        category_id={category_id}
        category_type={category_type}
        dispatch={dispatchCategoryItem}
        handleSave={() => handleSave(false)}
        handleAnother={() => handleSave(true)}
      />
      {saving && <CustomBackdrop label="Creating Category Item" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateCategoryItem));
