import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  categoryMenu: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: theme.spacing(1, 2),
    borderBottom: '1px solid #ddd',
    position: 'sticky',
    top: theme.spacing(8),
    zIndex: 100,
    overflowX: 'auto',
  },
  categoryButton: {
    fontWeight: 'bold',
    fontSize: '1rem',
    textTransform: 'none',
    padding: theme.spacing(1, 2),
    color: '#000',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
    '&.active': {
      backgroundColor: '#000',
      color: '#fff',
    },
  },
}));

interface CategoriesProps {
  categories: any[];
  selectedCategory: string;
  onCategorySelect: (id: string) => void;
  menuData?: any;
}

const Categories: React.FC<CategoriesProps> = ({ categories, selectedCategory, onCategorySelect, menuData }) => {
  const classes = useStyles();

  return (
    <Box className={classes.categoryMenu}>
      {categories.map((category) => (
        <Button 
          key={category.id} 
          className={`${classes.categoryButton} ${selectedCategory === category.id ? 'active' : ''}`} 
          onClick={() => onCategorySelect(category.id)}
          style={selectedCategory === category.id ? {
            backgroundColor: menuData?.location_info?.ordering_system_primary_color || '#000',
            color: menuData?.location_info?.ordering_system_secondary_color || '#fff'
          } : {}}
        >
          {category.name}
        </Button>
      ))}
    </Box>
  );
};

export default Categories;
