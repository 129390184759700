import { v4 as uuidv4 } from 'uuid';
import { timeToSeconds } from '../../../../utils/stringUtils';
import { ValidationType } from '../../../../react-app-env';

export interface CategoryItem {
  name: string;
  description: string;
  active: boolean;
  tag: string;
  price: number;
  tax: number;
  prep_time: number;
  bypass_prep: boolean;
  should_print: boolean;
  auto_serve_when_ready: boolean;
  modifiers: Modifier[];
  allergies: Allergy[];
  imageData?: string;
  croppedImageData?: string;
  image_added: boolean;
  score: number;
}

export interface AllergyAssignment {
  id: string;
  allergy: Allergy;
}

export interface Allergy {
  id: string;
  name: string;
  description: string;
  allergy_type: string;
}

export enum CategoryType {
  DISH = 'DISH',
  DRINK = 'DRINK',
}

export interface ValidationErrors {
  name: ValidationType;
  description: ValidationType;
  tag: ValidationType;
  tax: ValidationType;
}

export const validationErrors: ValidationErrors = {
  name: 'Please provide a name',
  description: 'Please provide a description',
  tag: 'Tag name is over the character limit',
  tax: 'Please provide a valid percentage',
};

export interface Validation {
  name: (name: string) => ValidationType;
  description: (description: string) => ValidationType;
  tag: (tag: string) => ValidationType;
  tax: (tax: number) => ValidationType;
}

export const validation: Validation = {
  name: (name) => (!name ? validationErrors.name : false),
  description: (description) => (!description ? validationErrors.description : false),
  tag: (tag) => (tag.length > 30 ? validationErrors.tag : false),
  tax: (tax) => (tax < 0 || tax > 1000 ? validationErrors.tax : false),
};

export enum CategoryItemReducerAction {
  NAME,
  DESCRIPTION,
  ACTIVE,
  TAG,
  PRICE,
  TAX,
  PREP_TIME,
  BYPASS_PREP,
  SHOULD_PRINT,
  AUTO_SERVE_WHEN_READY,
  ADD_MODIFIER,
  UPDATE_MODIFIER,
  DELETE_MODIFIER,
  UPDATE_ALLERGIES,
  SET_IMAGE,
  SET_CROPPED_IMAGE,
  CLEAR_IMAGE,
  SET_SCORE,
  INIT,
}

export const categoryItemReducer = (state: CategoryItem, action: { type: CategoryItemReducerAction; value: any }): CategoryItem => {
  switch (action.type) {
    case CategoryItemReducerAction.NAME:
      return { ...state, name: action.value };
    case CategoryItemReducerAction.DESCRIPTION:
      return { ...state, description: action.value };
    case CategoryItemReducerAction.ACTIVE:
      return { ...state, active: action.value as boolean };
    case CategoryItemReducerAction.TAG:
      return { ...state, tag: action.value };
    case CategoryItemReducerAction.PRICE:
      return { ...state, price: Math.round(+action.value * 100) };
    case CategoryItemReducerAction.TAX:
      return { ...state, tax: Math.round(+(+action.value * 10)) };
    case CategoryItemReducerAction.PREP_TIME:
      return { ...state, prep_time: timeToSeconds(action.value) };
    case CategoryItemReducerAction.BYPASS_PREP:
      return { ...state, bypass_prep: action.value as boolean, auto_serve_when_ready: false };
    case CategoryItemReducerAction.SHOULD_PRINT:
      return { ...state, should_print: action.value as boolean };
    case CategoryItemReducerAction.AUTO_SERVE_WHEN_READY:
      return { ...state, auto_serve_when_ready: action.value as boolean };
    case CategoryItemReducerAction.ADD_MODIFIER:
      state.modifiers.push({
        id: uuidv4(),
        name: '',
        price: 0,
        tax: action.value.taxRate,
      });
      return { ...state };
    // case CategoryItemReducerAction.UPDATE_MODIFIER:
    //   const mIndex = state.modifiers.findIndex((i) => i.id === action.value.id);
    //   if (mIndex >= 0) {
    //     state.modifiers[mIndex] = action.value;
    //   }
    //   return { ...state };
    case CategoryItemReducerAction.DELETE_MODIFIER:
      return { ...state, modifiers: state.modifiers.filter((i) => i.id !== action.value.id) };
    case CategoryItemReducerAction.UPDATE_ALLERGIES:
      return { ...state, allergies: action.value };
    case CategoryItemReducerAction.UPDATE_MODIFIER:
      return { ...state, modifiers: action.value };
    case CategoryItemReducerAction.SET_SCORE:
      return { ...state, score: action.value };
    case CategoryItemReducerAction.INIT:
      const {
        name,
        description,
        tag,
        price,
        tax,
        active,
        prep_time,
        bypass_prep,
        should_print,
        auto_serve_when_ready,
        modifier_assignments,
        allergy_assignments,
        image_added,
        score,
      } = action.value;
      const modifiers = modifier_assignments ? modifier_assignments.map((i: ModifierAssignment) => i.modifier) : [];
      const allergies = allergy_assignments ? allergy_assignments.map((i: AllergyAssignment) => i.allergy) : [];
      const obj = {
        name,
        description,
        tag,
        price,
        tax,
        active,
        prep_time,
        bypass_prep,
        should_print,
        auto_serve_when_ready,
        modifiers,
        allergies,
        image_added,
        score,
      };
      return { ...(obj as CategoryItem) };
    case CategoryItemReducerAction.SET_IMAGE:
      return {
        ...state,
        imageData: action.value,
      };
    case CategoryItemReducerAction.SET_CROPPED_IMAGE:
      return {
        ...state,
        croppedImageData: action.value,
      };
    case CategoryItemReducerAction.CLEAR_IMAGE:
      return {
        ...state,
        imageData: undefined,
        croppedImageData: undefined,
        image_added: false,
      };
    default:
      throw new Error();
  }
};

export interface Modifier {
  id: string;
  name: string;
  price: number;
  tax: number;
}

export interface ModifierAssignment {
  id: string;
  modifier: Modifier;
}

export interface ModifierValidationErrors {
  name: ValidationType;
  price: ValidationType;
  tax: ValidationType;
}

export const modifierValidationErrors: ModifierValidationErrors = {
  name: 'Please provide a name',
  price: 'Please provide a valid price',
  tax: 'Please provide a valid percentage',
};

export interface ModifierValidation {
  name: (name: string) => ValidationType;
  price: (price: number) => ValidationType;
  tax: (tax: number) => ValidationType;
}

export const modifierValidation: ModifierValidation = {
  name: (name) => (!name ? modifierValidationErrors.name : false),
  price: (price) => (price < 0 ? modifierValidationErrors.price : false),
  tax: (tax) => (tax < 0 || tax > 1000 ? modifierValidationErrors.tax : false),
};

export enum ModifierItemReducerAction {
  NAME,
  PRICE,
  TAX,
  INIT,
}

export const modifierItemReducer = (state: Modifier, action: { type: ModifierItemReducerAction; value: any }): Modifier => {
  switch (action.type) {
    case ModifierItemReducerAction.NAME:
      return { ...state, name: action.value };
    case ModifierItemReducerAction.PRICE:
      return { ...state, price: Math.round(+action.value * 100) };
    case ModifierItemReducerAction.TAX:
      return { ...state, tax: Math.round(+action.value * 10) };
    case ModifierItemReducerAction.INIT:
      const { id, name, price, tax } = action.value;
      const obj = {
        id,
        name,
        price,
        tax,
      };
      return { ...(obj as Modifier) };
    default:
      throw new Error();
  }
};
