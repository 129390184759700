import React, { ChangeEvent, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { generateOnboardingLink, getAccountStatus, disconnectPayPalAccount, useGetPaymentSettings, useUpdatePaymentSettings, manualOnboardingCheck } from './queries';
import { LocationIntegrationReducerAction, PaymentOnlineIntegration } from '../../../../index';
import ThreeDSecureSettings from '../ThreeDSecureSettings';
import TransactionList from '../TransactionList';
import { Box, FormControlLabel, Typography, Switch } from '@material-ui/core';

interface SellerOnboardingLightboxProps {
  handleSave: () => void;
  dispatch: React.Dispatch<any>;
  payment: PaymentOnlineIntegration;
  locationId: string;
  partnerLogo: string;
}

const SellerOnboardingLightbox: React.FC<SellerOnboardingLightboxProps> = ({ handleSave, dispatch, payment, locationId, partnerLogo }) => {
  const [actionUrl, setActionUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [initialResponse, setInitialResponse] = useState<boolean>(false);

  const [paymentSettings, setPaymentSettings] = useState({
    applePay: false,
    googlePay: false,
    cards: false,
    paypal: false,
  });

  // Mutation to generate the onboarding link
  const { mutateAsync: generateLink, isLoading: generatingLink } = useMutation(
    (product: string) => generateOnboardingLink(locationId, product, partnerLogo, window.location.origin),
    {
      mutationKey: 'onboarding-link-generate',
    }
  );

  // Mutation to manually onboard
  const { isLoading: manualOnboardingLoading } = useQuery(['paypal-account-onboarding-status', payment?.merchantId], () => manualOnboardingCheck(locationId), {
    onSuccess: (data) => {
      handleRefresh(data);
    },
    enabled: !payment?.merchantId,
  });

  // Mutation to disconnect PayPal account
  const { mutateAsync: disconnectAccount, isLoading: disconnecting } = useMutation(() => disconnectPayPalAccount(payment.merchantId), {
    mutationKey: 'disconnect-paypal-account',
  });

  // Load PayPal's Lightbox script for the mini-browser experience
  useEffect(() => {
    const loadPayPalScript = () => {
      const scriptId = 'paypal-js';
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://www.paypal.com/webapps/merchantboarding/js/lib/lightbox/partner.js';
        script.async = true;
        document.body.appendChild(script);
      }
    };
    loadPayPalScript();
  }, []);

  // Function to start the standard onboarding process
  const startOnboarding = async () => {
    setError(null);

    try {
      const response = await generateLink('PPCP');

      dispatch({
        type: LocationIntegrationReducerAction.ONLINE_PAYMENT_LINKS,
        value: response.links,
      });
      dispatch({
        type: LocationIntegrationReducerAction.ONLINE_PAYMENT_ISONBOARDED,
        value: false,
      });
      setInitialResponse(true);
    } catch (err) {
      console.error('Onboarding error:', err);
      setError('Failed to start onboarding process. Please check your network or try again later.');
    }
  };

  // Effect to handle the onboarding link and action URL
  useEffect(() => {
    if (!payment.isOnboarded && (payment.links ?? []).length > 0) {
      const actionUrl = payment.links?.find((link: { rel: string }) => link.rel === 'action_url')?.href;
      setActionUrl(actionUrl);

      if (initialResponse) {
        setInitialResponse(false);
        handleSave();
      }
    }
  }, [payment, initialResponse, handleSave]);

  // Query to get the account status after onboarding
  const {
    data: accountStatus,
    isLoading: accountStatusLoading,
    error: accountStatusError,
    refetch: refetchAccountStatus,
  } = useQuery(['paypal-account-status', payment?.merchantId], () => getAccountStatus(payment?.merchantId), {
    // @ts-ignore
    enabled: !!payment?.merchantId && payment.isOnboarded,
  });

  const { data, isLoading, error: paymentSettingsError } = useGetPaymentSettings(locationId);

  const { mutate: updateSettings, isLoading: isUpdating } = useUpdatePaymentSettings(locationId);

  useEffect(() => {
    if (data) {
      setPaymentSettings(data);
    }
  }, [data]);

  const handleToggle = (method: string) => (event: ChangeEvent<{}>, checked: boolean) => {
    const newSettings = { ...paymentSettings, [method]: checked };
    setPaymentSettings(newSettings);

    updateSettings({ paymentSettings: { ...newSettings } });
  };

  // Function to manually refresh the onboarding status
  const refreshAccountStatus = () => {
    refetchAccountStatus();
  };

  // Function to disconnect PayPal account
  const handleDisconnect = async () => {
    const confirmDisconnect = window.confirm(
      'Disconnecting your PayPal account will prevent you from offering PayPal services and products on your website. Do you wish to continue?'
    );

    if (confirmDisconnect) {
      try {
        const result = await disconnectAccount();
        if (result.status) {
          // Update the local state and dispatch actions to reflect disconnection
          dispatch({
            type: LocationIntegrationReducerAction.ONLINE_PAYMENT_ISONBOARDED,
            value: false,
          });
          dispatch({
            type: LocationIntegrationReducerAction.ONLINE_PAYMENT_MERCHANTID,
            value: null,
          });
          dispatch({
            type: LocationIntegrationReducerAction.ONLINE_PAYMENT_LINKS,
            value: [],
          });
          setActionUrl(null);
          // Optionally, you can reset the account status
          // handleSave();
        }
      } catch (err) {
        console.error('Failed to disconnect PayPal account:', err);
        setError('Failed to disconnect PayPal account. Please try again later.');
      }
    }
  };

  const handleRefresh = (data: any) => {
    if (data) {
      dispatch({
        type: LocationIntegrationReducerAction.ONLINE_PAYMENT_ISONBOARDED,
        value: data.isOnboarded,
      });
      dispatch({
        type: LocationIntegrationReducerAction.ONLINE_PAYMENT_LINKS,
        value: data.links,
      });
      dispatch({
        type: LocationIntegrationReducerAction.ONLINE_PAYMENT_MERCHANTID,
        value: data.merchantId,
      });
      setInitialResponse(true);
    }
  };

  // Check the vetting status
  let vettingMessage = '';

  if (accountStatus) {
    const advancedCreditDebitProduct = accountStatus.products.find((product: { name: string }) => product.name === 'PPCP');

    const vettingStatus = advancedCreditDebitProduct ? advancedCreditDebitProduct.vetting_status : null;

    if (vettingStatus === 'DENIED') {
      // Calculate reapplication date (90 days from denial date)
      const deniedDate = new Date(advancedCreditDebitProduct.vetting_status_update_time);
      const reapplyDate = new Date(deniedDate);
      reapplyDate.setDate(reapplyDate.getDate() + 90);

      vettingMessage = `Your application for Advanced Credit and Debit Card Payments was denied. You can reapply on ${reapplyDate.toDateString()}.`;
    } else if (vettingStatus === 'RESTRICTED') {
      vettingMessage = 'Your application requires additional information. Please check your PayPal account for more details.';
    } else if (vettingStatus === 'UNDER_REVIEW') {
      vettingMessage = 'Your application is under review. Please wait for PayPal to complete the review process.';
    } else if (vettingStatus === 'NEED_MORE_DATA') {
      vettingMessage =
        'PayPal requires more information about your business on paypal.com to enable Advanced Credit and Debit Card Payments. Please visit https://www.paypal.com/policy/hub/kyc. Until then you are still offering all other PayPal payment methods to your customers. Once sorted, simply revisit this page to refresh the onboarding status';
    }
  }

  return (
    <div>
      <h1>Seller Onboarding with PayPal</h1>

      {/* Display the Start Onboarding button if not onboarded */}
      {!payment.isOnboarded && (payment.links ?? []).length === 0 && (
        <>
          <button onClick={startOnboarding} disabled={generatingLink} aria-label="Start onboarding process with PayPal">
            {generatingLink ? 'Generating Onboarding Link...' : 'Start Onboarding'}
          </button>
        </>
      )}

      {/* Display success message and account information if onboarded */}
      {payment.isOnboarded && accountStatus && (
        <>
          <div>
            <h2>You have been successfully onboarded. You can use PayPal for payments.</h2>
            <p>Your PayPal Merchant ID: {accountStatus.merchant_id}</p>
            <p>Your PayPal Email: {accountStatus.primary_email}</p>

            {/* Display vetting messages */}
            {vettingMessage && <p style={{ color: 'red' }}>{vettingMessage}</p>}

            {/* Display error if payments_receivable is false */}
            {!accountStatus.payments_receivable && (
              <p style={{ color: 'red' }}>
                Attention: You currently cannot receive payments due to restrictions on your PayPal account. Please reach out to PayPal Customer Support or visit{' '}
                <a href="https://www.paypal.com" target="_blank" rel="noopener noreferrer">
                  https://www.paypal.com
                </a>{' '}
                for more information.
              </p>
            )}

            {/* Display error if primary_email_confirmed is false */}
            {!accountStatus.primary_email_confirmed && (
              <p style={{ color: 'red' }}>
                Attention: Please confirm your email address on{' '}
                <a href="https://www.paypal.com/businessprofile/settings" target="_blank" rel="noopener noreferrer">
                  PayPal Business Settings
                </a>{' '}
                in order to receive payments! You currently cannot receive payments.
              </p>
            )}

            {/* Display error if accountStatusError exists */}
            {accountStatusError && <p style={{ color: 'red' }}>Failed to fetch account status. Please try again later.</p>}

            {/* Button to manually refresh account status */}
            {/*<button onClick={refreshAccountStatus} disabled={accountStatusLoading}>*/}
            {/*  {accountStatusLoading ? 'Refreshing...' : 'Refresh Account Status'}*/}
            {/*</button>*/}

            {/* Button to disconnect PayPal account */}
            <button onClick={handleDisconnect} disabled={disconnecting} style={{ marginLeft: '10px' }}>
              {disconnecting ? 'Disconnecting...' : 'Disconnect PayPal'}
            </button>
          </div>
          <Box>
            <Typography variant="h6">Payment Methods</Typography>
            <FormControlLabel control={<Switch color="primary" />} checked={paymentSettings.applePay} onChange={handleToggle('applePay')} label="Apple Pay" />
            <FormControlLabel control={<Switch color="primary" />} checked={paymentSettings.googlePay} onChange={handleToggle('googlePay')} label="Google Pay" />
            <FormControlLabel control={<Switch color="primary" />} checked={paymentSettings.cards} onChange={handleToggle('cards')} label="Cards" />
            <FormControlLabel control={<Switch color="primary" />} checked={paymentSettings.paypal} onChange={handleToggle('paypal')} label="PayPal" />
          </Box>
          <ThreeDSecureSettings locationId={locationId} />
        </>
      )}

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {/* Display the PayPal Lightbox link */}
      {actionUrl && (
        <>
          <div>
            <a data-paypal-button="true" href={`${actionUrl}&displayMode=minibrowser`} target="PPFrame" aria-label="Sign up for PayPal via Lightbox">
              Sign up for PayPal
            </a>
          </div>
          <br />
          {/*<Button variant={'contained'} color={'primary'} onClick={handleManualOnboarding} disabled={manualOnboardingLoading}>*/}
          {/*  {manualOnboardingLoading ? 'Checking...' : 'Manual Onboarding Check'}*/}
          {/*</Button>*/}
        </>
      )}
      <TransactionList merchantId={payment.merchantId!} locationId={locationId} />
    </div>
  );
};

export default SellerOnboardingLightbox;
