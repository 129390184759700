import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    actions: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start',
    },
    saveButton: {
      marginBottom: theme.spacing(2),
    },
    dialogContent: {
      minWidth: 400,
    },
    formField: {
      marginBottom: theme.spacing(2),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    applyToAllContainer: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
    },
    applyToAllTitle: {
      marginBottom: theme.spacing(1),
      fontWeight: 500,
    },
    applyToAllDescription: {
      marginBottom: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  });

export default styles;
