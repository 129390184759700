import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 0),
    },
    list: {
      height: '100%',
    },
    paper: {
      width: '100%',
      overflow: 'auto',
      height: 500,
    },
    button: {
      margin: theme.spacing(0.5, 0),
    },
    listTitle: {
      padding: theme.spacing(1, 2),
    },
    search: {
      padding: theme.spacing(0, 1),
      display: 'flex',
      alignItems: 'center',
      height: 40,
    },
    searchInput: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    searchButton: {
      padding: 10,
      cursor: 'pointer',
    },
    description: {
      textAlign: 'right',
      display: 'block',
    },
    priceInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(0.5),
    },
    categoryModifierLabel: {
      marginTop: theme.spacing(0.5),
      fontWeight: 500,
      fontSize: '0.75rem',
    },
    categoryModifierItem: {
      backgroundColor: theme.palette.action.hover,
      borderLeft: `3px solid ${theme.palette.primary.main}`,
    },
  });

export default styles;
