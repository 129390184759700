import React, { useEffect, useState } from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Button, Box, TextField, InputAdornment, IconButton, Popover } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import { Location, LocationReducerAction } from '../..';
import ImageCropper from '../../../../../ImageCropper';
import FileInput from '../../../../../FileUpload';
import ColorLensIcon from '@material-ui/icons/ColorLens';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    sectionDescription: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      marginBottom: theme.spacing(3),
    },
    inputField: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    colorPreview: {
      width: 24,
      height: 24,
      borderRadius: '50%',
      marginRight: theme.spacing(1),
    },
    stickyButtonContainer: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      zIndex: 1000,
    },
    stickyButton: {
      paddingInline: theme.spacing(4),
    },
  });

interface BrandingProps extends WithStyles<typeof styles> {
  location: Location;
  locationId: string | undefined;
  dispatch: React.Dispatch<{ type: LocationReducerAction; value: any }>;
  handleSave?: () => void;
  isLocationCreate: boolean;
}

const Branding: React.FC<BrandingProps> = ({ classes, location, locationId, dispatch, handleSave, isLocationCreate }) => {
  const [initialState, setInitialState] = useState<Location | null>(null);
  const [stateChanged, setStateChanged] = useState(false);

  useEffect(() => {
    setInitialState(location);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialState && JSON.stringify(initialState) !== JSON.stringify(location)) {
      setStateChanged(true);
    } else {
      setStateChanged(false);
    }
  }, [location, initialState]);
  const [colorPickerAnchor, setColorPickerAnchor] = useState<null | HTMLElement>(null);
  const [currentColorField, setCurrentColorField] = useState<LocationReducerAction | null>(null);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>, colorField: LocationReducerAction) => {
    setColorPickerAnchor(event.currentTarget);
    setCurrentColorField(colorField);
  };

  const handleClose = () => {
    setColorPickerAnchor(null);
    setCurrentColorField(null);
  };

  const handleColorChange = (color: any) => {
    if (currentColorField) {
      dispatch({ type: currentColorField, value: color.hex });
    }
  };

  const getColorValue = (color: string | null | undefined, defaultColor: string) => {
    return color || defaultColor;
  };
  const primaryColor = getColorValue(location.ordering_system_primary_color, '#3b4d19');
  const secondaryColor = getColorValue(location.ordering_system_secondary_color, '#7bbf40');

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Branding
        </Typography>
      </Grid>

      {/* Logo Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Logo
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            How many tables you have in total within the location
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="subtitle1">Logo Image</Typography>
            </Grid>
            {(location.logo_image_data || location.logo_image_added) && (
              <Grid item>
                <Button variant="contained" onClick={() => dispatch({ type: LocationReducerAction.CLEAR_LOGO_IMAGE, value: null })}>
                  Clear selection
                </Button>
              </Grid>
            )}
          </Grid>
          {location.logo_image_data || location.logo_image_added ? (
            <Grid item xs={12}>
              <ImageCropper
                onCropComplete={(croppedImage) =>
                  dispatch({
                    type: LocationReducerAction.SET_LOGO_IMAGE,
                    value: croppedImage,
                  })
                }
                image={location.logo_image_data}
                imageUrl={`${process.env.REACT_APP_AWS_ORDERING_SYSTEM_IMAGES_URL}/${locationId}-logo.jpeg`}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <FileInput
                label="Click to upload"
                onImageSelected={(img) =>
                  dispatch({
                    type: LocationReducerAction.SET_LOGO_IMAGE_DATA,
                    value: img,
                  })
                }
              />
            </Grid>
          )}
        </Paper>
      </Grid>

      {/* Cover Image Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Cover Image
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            How many tables you have in total within the location
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography variant="subtitle1">Header Image</Typography>
            </Grid>
            {(location.header_image_data || location.header_image_added) && (
              <Grid item>
                <Button variant="contained" onClick={() => dispatch({ type: LocationReducerAction.CLEAR_HEADER_IMAGE, value: null })}>
                  Clear selection
                </Button>
              </Grid>
            )}
          </Grid>
          {location.header_image_data || location.header_image_added ? (
            <Grid item xs={12}>
              <ImageCropper
                onCropComplete={(croppedImage) =>
                  dispatch({
                    type: LocationReducerAction.SET_HEADER_IMAGE,
                    value: croppedImage,
                  })
                }
                image={location.header_image_data}
                imageUrl={`${process.env.REACT_APP_AWS_ORDERING_SYSTEM_IMAGES_URL}/${locationId}.jpeg`}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <FileInput
                label="Click to upload"
                onImageSelected={(img) =>
                  dispatch({
                    type: LocationReducerAction.SET_HEADER_IMAGE_DATA,
                    value: img,
                  })
                }
              />
            </Grid>
          )}
        </Paper>
      </Grid>

      {/* Colors Section */}
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Colours
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Set the primary and secondary colors for your location.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Primary"
                variant="outlined"
                value={primaryColor}
                onChange={(e) => handleColorChange({ hex: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box className={classes.colorPreview} style={{ backgroundColor: primaryColor }} />
                      <IconButton onClick={(event) => handleClickOpen(event, LocationReducerAction.SET_ORDERING_SYSTEM_PRIMARY_COLOR)}>
                        <ColorLensIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={classes.inputField}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Secondary"
                variant="outlined"
                value={secondaryColor}
                onChange={(e) => handleColorChange({ hex: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box className={classes.colorPreview} style={{ backgroundColor: secondaryColor }} />
                      <IconButton onClick={(event) => handleClickOpen(event, LocationReducerAction.SET_ORDERING_SYSTEM_SECONDARY_COLOR)}>
                        <ColorLensIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                className={classes.inputField}
              />
            </Grid>
          </Grid>
          <Box mt={3}>
            <Typography variant="subtitle2" gutterBottom>
              Button Preview
            </Typography>
            <Button
              variant="outlined"
              style={{
                backgroundColor: primaryColor,
                color: secondaryColor,
                marginTop: '8px',
              }}>
              Preview Button
            </Button>
            <Typography variant="caption" display="block" style={{ marginTop: '8px' }}>
              This shows how your buttons will appear with primary as background and secondary as text color
            </Typography>
          </Box>
        </Paper>
      </Grid>

      <Popover
        open={Boolean(colorPickerAnchor)}
        anchorEl={colorPickerAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <ChromePicker color={currentColorField === LocationReducerAction.SET_ORDERING_SYSTEM_PRIMARY_COLOR ? primaryColor : secondaryColor} onChange={handleColorChange} />
      </Popover>
      {stateChanged && !isLocationCreate && (
        <Box className={classes.stickyButtonContainer}>
          <Button variant="contained" color="primary" className={classes.stickyButton} onClick={handleSave}>
            Save changes
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default withStyles(styles)(Branding);
