interface CartState {
  cart: { [key: string]: number };
  orderDetails: OrderDetails;
}

export interface OrderDetails {
  orderType: 'delivery' | 'pickup' | 'dinein' | null;
  timeOption: 'standard' | 'schedule';
  scheduledDay: string;
  scheduledTime: string;
  tableNumber: number | null;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  deliveryAddress: string;
  deliveryPostcode: string;
}

export type Action =
  | { type: 'ADD_TO_CART'; payload: { cartKey: string; quantity: number } }
  | { type: 'REMOVE_FROM_CART'; payload: { cartKey: string } }
  | { type: 'UPDATE_ORDER_DETAILS'; payload: Partial<OrderDetails> }
  | { type: 'RESET_STATE' }
  | { type: 'RESET_KIOSK_STATE' };

export const initialState: CartState = {
  cart: {},
  orderDetails: {
    orderType: 'pickup',
    timeOption: 'schedule',
    scheduledDay: '',
    scheduledTime: '',
    tableNumber: null,
    customerName: '',
    customerPhone: '',
    customerEmail: '',
    deliveryAddress: '',
    deliveryPostcode: '',
  },
};

export const initialStateKiosk: CartState = {
  cart: {},
  orderDetails: {
    orderType: 'dinein',
    timeOption: 'schedule',
    scheduledDay: '',
    scheduledTime: '',
    tableNumber: null,
    customerName: '',
    customerPhone: '',
    customerEmail: '',
    deliveryAddress: '',
    deliveryPostcode: '',
  },
};

export const reducer = (state: CartState, action: Action): CartState => {
  switch (action.type) {
    case 'ADD_TO_CART':
      const { cartKey, quantity } = action.payload;
      const currentQuantity = state.cart[cartKey] || 0;
      return {
        ...state,
        cart: {
          ...state.cart,
          [cartKey]: currentQuantity + quantity,
        },
      };
    case 'REMOVE_FROM_CART': {
      const { cartKey } = action.payload;
      const newCart = { ...state.cart };
      delete newCart[cartKey];
      return {
        ...state,
        cart: newCart,
      };
    }
    case 'UPDATE_ORDER_DETAILS':
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          ...action.payload,
        },
      };
    case 'RESET_STATE':
      return {...initialState};
    case 'RESET_KIOSK_STATE':
      return {...initialStateKiosk};
    default:
      return state;
  }
};
