import { gql } from '@apollo/client';
import gqlClient from '../../../../../../utils/apolloClient';

// Dish Category Modifier Assignments
const insertDishCategoryModifierAssignmentMutation = gql`
  mutation InsertDishCategoryModifierAssignment($object: dish_category_modifier_assignments_insert_input!) {
    insert_dish_category_modifier_assignments_one(object: $object) {
      id
    }
  }
`;

export const insertDishCategoryModifierAssignment = async (variables: { object: any }) => {
  return await gqlClient.mutate<{
    insert_dish_category_modifier_assignments_one: {
      id: string;
    };
  }>({
    mutation: insertDishCategoryModifierAssignmentMutation,
    variables,
  });
};

const deleteDishCategoryModifierAssignmentMutation = gql`
  mutation DeleteDishCategoryModifierAssignment($id: uuid!) {
    delete_dish_category_modifier_assignments_by_pk(id: $id) {
      id
    }
  }
`;

export const deleteDishCategoryModifierAssignment = async (variables: { id: string }) => {
  return await gqlClient.mutate<{
    delete_dish_category_modifier_assignments_by_pk: {
      id: string;
    };
  }>({
    mutation: deleteDishCategoryModifierAssignmentMutation,
    variables,
  });
};

// Drink Category Modifier Assignments
const insertDrinkCategoryModifierAssignmentMutation = gql`
  mutation InsertDrinkCategoryModifierAssignment($object: drink_category_modifier_assignments_insert_input!) {
    insert_drink_category_modifier_assignments_one(object: $object) {
      id
    }
  }
`;

export const insertDrinkCategoryModifierAssignment = async (variables: { object: any }) => {
  return await gqlClient.mutate<{
    insert_drink_category_modifier_assignments_one: {
      id: string;
    };
  }>({
    mutation: insertDrinkCategoryModifierAssignmentMutation,
    variables,
  });
};

const deleteDrinkCategoryModifierAssignmentMutation = gql`
  mutation DeleteDrinkCategoryModifierAssignment($id: uuid!) {
    delete_drink_category_modifier_assignments_by_pk(id: $id) {
      id
    }
  }
`;

export const deleteDrinkCategoryModifierAssignment = async (variables: { id: string }) => {
  return await gqlClient.mutate<{
    delete_drink_category_modifier_assignments_by_pk: {
      id: string;
    };
  }>({
    mutation: deleteDrinkCategoryModifierAssignmentMutation,
    variables,
  });
};

// Mutations to assign modifiers to all items in a category
const insertDishModifierAssignmentsMutation = gql`
  mutation InsertDishModifierAssignments($objects: [dish_modifier_assignments_insert_input!]!) {
    insert_dish_modifier_assignments(objects: $objects) {
      affected_rows
    }
  }
`;

export const insertDishModifierAssignments = async (variables: { objects: any[] }) => {
  return await gqlClient.mutate<{
    insert_dish_modifier_assignments: {
      affected_rows: number;
    };
  }>({
    mutation: insertDishModifierAssignmentsMutation,
    variables,
  });
};

const insertDrinkModifierAssignmentsMutation = gql`
  mutation InsertDrinkModifierAssignments($objects: [drink_modifier_assignments_insert_input!]!) {
    insert_drink_modifier_assignments(objects: $objects) {
      affected_rows
    }
  }
`;

export const insertDrinkModifierAssignments = async (variables: { objects: any[] }) => {
  return await gqlClient.mutate<{
    insert_drink_modifier_assignments: {
      affected_rows: number;
    };
  }>({
    mutation: insertDrinkModifierAssignmentsMutation,
    variables,
  });
};

// Queries to get all items in a category
const getDishesInCategoryQuery = gql`
  query GetDishesInCategory($categoryId: uuid!) {
    dishes(where: {category_assignments: {category_id: {_eq: $categoryId}}}) {
      id
      organisation_id
    }
  }
`;

export const getDishesInCategory = async (variables: { categoryId: string }) => {
  return await gqlClient.query<{
    dishes: Array<{
      id: string;
      organisation_id: string;
    }>;
  }>({
    query: getDishesInCategoryQuery,
    variables,
    fetchPolicy: 'no-cache',
  });
};

const getDrinksInCategoryQuery = gql`
  query GetDrinksInCategory($categoryId: uuid!) {
    drinks(where: {category_assignments: {category_id: {_eq: $categoryId}}}) {
      id
      organisation_id
    }
  }
`;

export const getDrinksInCategory = async (variables: { categoryId: string }) => {
  return await gqlClient.query<{
    drinks: Array<{
      id: string;
      organisation_id: string;
    }>;
  }>({
    query: getDrinksInCategoryQuery,
    variables,
    fetchPolicy: 'no-cache',
  });
};
