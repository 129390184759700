import { types, query, params, rawString } from 'typed-graphqlify';
import { gql } from '@apollo/client';
import { Unpacked } from '../../../../react-app-env';

const _editCategoryInitQuery = (id: string) => ({
  categories_by_pk: params(
    { id: rawString(id) },
    {
      name: types.string,
      colour_id: types.string,
      active: types.string,
      score: types.number,
      dish_category_modifier_assignments: [
        {
          id: types.string,
          modifier: {
            id: types.string,
            name: types.string,
            price: types.number,
            tax: types.number,
          },
        },
      ],
      drink_category_modifier_assignments: [
        {
          id: types.string,
          modifier: {
            id: types.string,
            name: types.string,
            price: types.number,
            tax: types.number,
          },
        },
      ],
    }
  ),
});

type X = Unpacked<typeof _editCategoryInitQuery>;
export type Category = X['categories_by_pk'];

export const editCategoryInitQuery = (id: string) => gql`
  ${query(_editCategoryInitQuery(id))}
`;
