import React, { useState } from 'react';
import { Box, Button, DialogTitle, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, TextField, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import QrCodeDisplay from '../../../Redesign/Common/QrPayment';
import { CategoryType } from '../../../Common';
import { OrderDetails, Action } from '../../../Redesign/Common/State';

const useStyles = makeStyles((theme) => ({
  checkoutContainer: {
    padding: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  orderSummary: {
    marginBottom: theme.spacing(2),
  },
  totalPrice: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
}));

interface CartItem {
  cartKey: string;
  productId: string;
  modifiersKey: string;
  name: string;
  description: string;
  price: number;
  quantity: number;
}

interface CheckoutProps {
  orderDetails: OrderDetails;
  dispatch: React.Dispatch<Action>;
  totalPrice: number;
  onClose: () => void;
  cartItems: CartItem[];
  cart: { [key: string]: number };
  menuData: any;
  location_id: string;
  organisation_id: string;
}

const KioskCheckout: React.FC<CheckoutProps> = ({ orderDetails, dispatch, totalPrice, onClose, cartItems, cart, menuData, location_id, organisation_id }) => {
  const classes = useStyles();

  const [showPayPalCheckout, setShowPayPalCheckout] = useState(false);

  const calculateTotalPrice = (): number => {
    return Object.keys(cart).reduce((total, cartKey) => {
      const [productId, modifiersKey] = cartKey.split(':');
      const product = menuData?.categories
        .flatMap((category: { category_type: CategoryType; dish_assignments: any[]; drink_assignments: any[] }) =>
          category.category_type === CategoryType.DISH ? category.dish_assignments.map((item) => item.dish) : category.drink_assignments.map((item) => item.drink)
        )
        .find((item: { id: string }) => item.id === productId);

      if (!product) return total;

      const selectedModifiers = product.modifier_assignments
        .filter((mod: { modifier: { name: string } }) => modifiersKey.includes(mod.modifier.name))
        .map((mod: { modifier: any }) => mod.modifier);

      const itemPrice = selectedModifiers.reduce((sum: any, mod: { price: any }) => sum + mod.price, product.price);
      return total + ((itemPrice || 0) / 100) * cart[cartKey];
    }, 0);
  };

  const handleProceedToPayment = async () => {
    setShowPayPalCheckout(true);
  };

  const handleOrderTypeChange = (event: React.MouseEvent<HTMLElement>, newOrderType: 'dinein' | 'delivery' | 'pickup' | null) => {
    if (newOrderType !== null) {
      dispatch({ type: 'UPDATE_ORDER_DETAILS', payload: { orderType: newOrderType } });
    }
  };

  return (
    <div>
      <DialogTitle disableTypography>
        <Typography variant="h6">Checkout</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <Box className={classes.checkoutContainer}>
        {/* Order Summary */}
        <Typography variant="h6" gutterBottom>
          Order Summary
        </Typography>
        <List className={classes.orderSummary}>
          {cartItems.map((item) => (
            <ListItem key={item.cartKey}>
              <ListItemText primary={`${item.quantity} x ${item.name}`} secondary={item.description} />
              <ListItemSecondaryAction>
                <Typography variant="body2">£{(item.price * item.quantity).toFixed(2)}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Typography variant="body1" className={classes.totalPrice}>
          Total: £{totalPrice.toFixed(2)}
        </Typography>

        {/* Conditional Inputs Based on Order Type */}
        {orderDetails.orderType === 'dinein' && (
          <>
            <TextField
              label="Table Number"
              variant="outlined"
              fullWidth
              className={classes.formControl}
              value={orderDetails.tableNumber || ''}
              onChange={(e) =>
                dispatch({
                  type: 'UPDATE_ORDER_DETAILS',
                  payload: { tableNumber: e.target.value ? parseInt(e.target.value) : null },
                })
              }
            />
            <Box mt={2}>
              <Typography variant="h6">Customer Details</Typography>

              <Box className={classes.formControl}>
                <TextField
                  label="Customer Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={orderDetails.customerName}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ORDER_DETAILS',
                      payload: { customerName: e.target.value },
                    })
                  }
                />
              </Box>
              <Box className={classes.formControl}>
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={orderDetails.customerPhone}
                  onChange={(e) =>
                    dispatch({
                      type: 'UPDATE_ORDER_DETAILS',
                      payload: { customerPhone: e.target.value },
                    })
                  }
                />
              </Box>
              {/*<Box className={classes.formControl}>*/}
              {/*  <TextField*/}
              {/*    label="Email"*/}
              {/*    variant="outlined"*/}
              {/*    fullWidth*/}
              {/*    margin="normal"*/}
              {/*    value={orderDetails.customerEmail}*/}
              {/*    onChange={(e) =>*/}
              {/*      dispatch({*/}
              {/*        type: 'UPDATE_ORDER_DETAILS',*/}
              {/*        payload: { customerEmail: e.target.value },*/}
              {/*      })*/}
              {/*    }*/}
              {/*    required*/}
              {/*  />*/}
              {/*</Box>*/}
            </Box>
          </>
        )}

        {!showPayPalCheckout && (
          <Button 
            variant="contained" 
            style={{
              ...(menuData?.location_info?.ordering_system_primary_color ? {backgroundColor: menuData.location_info.ordering_system_primary_color} : {}),
              ...(menuData?.location_info?.ordering_system_secondary_color ? {color: menuData.location_info.ordering_system_secondary_color} : {})
            }}
            onClick={handleProceedToPayment}>
            Proceed to Payment
          </Button>
        )}

        {/* Place Order Button */}
        {showPayPalCheckout && (
          <QrCodeDisplay
            cart={cart}
            orderDetails={{
              orderType: 'dinein',
              tableNumber: orderDetails.tableNumber!,
              customer_name: orderDetails.customerName!,
              customer_phone: orderDetails.customerPhone!,
              total_amount: calculateTotalPrice(),
              covers: 1,
            }}
            totalPrice={calculateTotalPrice()}
            location_id={location_id}
            organisation_id={organisation_id}
            onPaymentComplete={() => {}}
            onError={(error) => console.log(error)}
          />
        )}

        {/* Reset Button - Always visible */}
        <Box mt={3} display="flex" justifyContent="center">
          <Button
            variant="outlined"
            style={{
              ...(menuData?.location_info?.ordering_system_secondary_color ? {
                borderColor: menuData.location_info.ordering_system_secondary_color,
                color: menuData.location_info.ordering_system_secondary_color
              } : {}),
              width: '100%'
            }}
            onClick={() => {
              dispatch({ type: 'RESET_KIOSK_STATE' });
              onClose();
            }}>
            Reset Page
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default KioskCheckout;
