import React, { useEffect, useReducer } from 'react';
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Header from '../Redesign/Common/Header';
import MenuCard from '../Redesign/Common/MenuCard';
import { useParams } from 'react-router-dom';
import { useQuery as useRestQuery } from 'react-query';
import { getMenuQuery } from '../Menu/queries';
import { initialStateKiosk, reducer } from '../Redesign/Common/State';
import { calculateTotalPrice } from '../../../../utils/priceCalculator';
import Categories from '../Redesign/Common/Categories';
import KioskCart from './Common/KioskCart';

const useStyles = makeStyles((theme: Theme) => ({
  layout: {
    backgroundColor: '#FAF9F6',
    height: '100%',
  },
  pageContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  menuSection: {
    flex: 1,
    paddingRight: theme.spacing(3),
  },
  menuGrid: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  categoryHeader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: theme.spacing(2, 0),
  },
  cartContainer: {
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    position: 'sticky',
    top: theme.spacing(2),
    height: 'fit-content',
  },
  categoriesBar: {
    position: 'sticky',
    top: 0,
    zIndex: 100,
    backgroundColor: '#FAF9F6',
    borderBottom: '1px solid #ddd',
    padding: theme.spacing(1, 0),
    textAlign: 'center',
  },
}));

interface UrlParams {
  organisation_id: string;
  location_id: string;
}

interface MenuData {
  categories: Category[];
  location_info: any;
}

interface Category {
  id: string;
  name: string;
  category_type: 'DISH' | 'DRINK';
  dish_assignments: { dish: any }[];
  drink_assignments: { drink: any }[];
}

const Kiosk: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, initialStateKiosk);
  const { cart, orderDetails } = state;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { organisation_id, location_id } = useParams<UrlParams>();

  const { data: menuData, refetch } = useRestQuery<MenuData>({
    queryKey: 'menu-data',
    queryFn: () => getMenuQuery(organisation_id, location_id),
  });

  const [selectedCategory, setSelectedCategory] = React.useState<string>('');

  const sortCategories = (categories: Category[]) => {
    return categories.sort((a, b) => {
      if (a.category_type === 'DISH' && b.category_type === 'DRINK') return -1;
      if (a.category_type === 'DRINK' && b.category_type === 'DISH') return 1;
      //@ts-ignore
      return a.order_index - b.order_index;
    });
  };

  useEffect(() => {
    if (menuData?.categories?.[0]?.id) {
      const sortedCategories = sortCategories([...menuData.categories]);
      setSelectedCategory(sortedCategories[0].id);
    }
  }, [menuData]);

  const handleCategorySelect = (categoryId: string) => {
    setSelectedCategory(categoryId);
  };
  const filteredMenuItems = React.useMemo(() => {
    const selectedCategoryData = menuData?.categories.find((category) => category.id === selectedCategory);

    if (!selectedCategoryData) return [];

    let items =
      selectedCategoryData.category_type === 'DISH'
        ? selectedCategoryData.dish_assignments.map((item) => item.dish)
        : selectedCategoryData.drink_assignments.map((item) => item.drink);

    return items.sort((a, b) => a.order_index - b.order_index);
  }, [menuData, selectedCategory]);
  //@ts-ignore
  const totalPrice = calculateTotalPrice(cart, menuData);

  return (
    <>
      {/* Header Section */}
      <div className={classes.layout}>
        {menuData?.location_info && <Header locationInfo={menuData.location_info} location_id={location_id} dispatch={dispatch} state={state} />}

        {/* Categories Bar */}
        <Box className={classes.categoriesBar}>
          {menuData?.categories && (
            <Categories categories={sortCategories([...menuData.categories])} selectedCategory={selectedCategory} onCategorySelect={handleCategorySelect} menuData={menuData} />
          )}
        </Box>

        {/* Main Content */}
        <Container maxWidth={false} className={classes.pageContainer}>
          <Grid container spacing={4}>
            {/* Menu Section */}
            <Grid item xs={12} md={8} className={classes.menuSection}>
              {selectedCategory && <Typography className={classes.categoryHeader}>{menuData?.categories.find((category) => category.id === selectedCategory)?.name}</Typography>}
              <div className={classes.menuGrid}>
                {filteredMenuItems.map((item) => (
                  <MenuCard
                    key={item.id}
                    primary={menuData?.location_info?.ordering_system_primary_color}
                    secondary={menuData?.location_info?.ordering_system_secondary_color}
                    item={{
                      ...item,
                      imageUrl: item.image_added ? `${process.env.REACT_APP_AWS_IMAGES_URL}/${item.id}.jpeg` : ``,
                    }}
                    cart={cart}
                    dispatch={dispatch}
                  />
                ))}
              </div>
            </Grid>

            {/* Cart Section */}
            <Grid item xs={12} md={4}>
              <div className={classes.cartContainer}>
                <KioskCart
                  cart={cart}
                  dispatch={dispatch}
                  menuData={menuData}
                  totalPrice={totalPrice}
                  //@ts-ignore
                  orderDetails={orderDetails}
                  location_id={location_id}
                  organisation_id={organisation_id}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/*{isPaymentProcessing && <LoadingOverlay message="Processing your payment..." />}*/}
    </>
  );
};

export default Kiosk;
