import React, { useState, useEffect, useReducer } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { locationIntegrationReducer, LocationIntegrationReducerAction, PaymentProvider } from '../Common';
import Steps from '../Common/components/Steps';

import { editLocationIntegrationsInitQuery } from './queries';
import { updateLocationIntegration, insertLocationIntegration } from './mutations';

import styles from './styles';
import { UnknownObject } from '../../../../react-app-env';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  location_id: string;
  handleFinish: () => void;
}

const EditLocationIntegrations = ({ classes, history, location_id, handleFinish }: Props): React.ReactElement => {
  const [integration, dispatchIntegration] = useReducer(locationIntegrationReducer, {
    id: '',
    payment: {
      active: false,
      provider: null,
      accountId: '',
      username: '',
      password: '',
    },
    onlinePayment: {
      active: false,
      provider: null,
      isOnboarded: false,
      links: null,
      merchantId: '',
    },
    deliverect: {
      active: false,
      accountId: '',
      locationId: '',
      autoAccept: false,
    },
    xero: {
      active: false,
      code: '',
    },
    vouchers: false,
  });

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editLocationIntegrationsInitQueryData } = useQuery(editLocationIntegrationsInitQuery(location_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editLocationIntegrationsInitQueryData) {
      dispatchIntegration({ type: LocationIntegrationReducerAction.INIT, value: editLocationIntegrationsInitQueryData.locations_by_pk.integrations[0] });
    }
    return () => {
      mounted = false;
    };
  }, [editLocationIntegrationsInitQueryData]);

  const handleSave = async () => {
    setSaving(true);

    const { provider } = integration.payment;

    let payment = null;
    let deliverect = null;
    let vouchers = integration.vouchers;

    if (integration.payment.active) {
      if (provider === PaymentProvider.DOJO) {
        payment = {
          provider,
          connection: {
            accountId: integration.payment.accountId,
            username: integration.payment.username,
            password: integration.payment.password,
          },
        };
      }
      if (provider === PaymentProvider.ZETTLE) {
        payment = {
          provider,
          enableTipping: integration.payment.enableTipping ?? false,
          connection: {
            accountId: integration.payment.accountId,
          },
        };
      }
    }

    if (integration.deliverect.active) {
      deliverect = {
        accountId: integration.deliverect.accountId,
        locationId: integration.deliverect.locationId,
        autoAccept: integration.deliverect.autoAccept,
      };
    }

    const data: UnknownObject = {
      location_id,
      payment,
      deliverect,
      vouchers,
    };
    if (integration.id) {
      const updateVariables = {
        pk_columns: {
          id: integration.id,
        },
        set: data,
      };
      await updateLocationIntegration(updateVariables);
    } else {
      const insertVariables = {
        object: data,
      };
      await insertLocationIntegration(insertVariables);
    }

    setSaving(false);
    handleFinish();
  };

  return (
    <>
      <Steps completeLabel="Save" integration={integration} location_id={location_id} dispatch={dispatchIntegration} handleSave={handleSave} />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditLocationIntegrations));
