// apolloClient.ts
import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Auth } from 'aws-amplify';

const { REACT_APP_API_URL: API_URL, REACT_APP_WS_URL: WS_URL } = process.env;

const httpLink = createHttpLink({
  uri: API_URL,
});

const authLink = setContext((_, { headers }) => {
  Auth.currentSession().then((res) => {
    let accessToken = res.getAccessToken();
    let jwt = accessToken.getJwtToken();
    localStorage.setItem('token', jwt);
  });

  const token = localStorage.getItem('token') || '';
  const userRole = localStorage.getItem('user_role') || '';
  const systemId = localStorage.getItem('system_id') || '';
  let bearer = token ? `Bearer ${token}` : '';
  if (userRole && systemId) {
    bearer += `|||${btoa(userRole)}|||${btoa(systemId)}`;
  }
  return {
    headers: {
      ...headers,
      Authorization: bearer,
    },
  };
});

const wsLink = new WebSocketLink({
  uri: WS_URL || 'ws://apos-api-core.localtest.me/v1/graphql',
  options: {
    reconnect: true,
    connectionParams: () => {
      const token = localStorage.getItem('token') || '';
      const userRole = localStorage.getItem('user_role') || '';
      const systemId = localStorage.getItem('system_id') || '';
      let bearer = token ? `Bearer ${token}` : '';
      if (userRole && systemId) {
        bearer += `|||${btoa(userRole)}|||${btoa(systemId)}`;
      }
      return {
        headers: {
          Authorization: bearer,
        },
      };
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default client;
