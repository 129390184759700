// c:\Users\fire2\Desktop\HoneyComb\Blum\apos-suite\packages\apps\core\src\views\Public\OrderingSystem\Menu\subscriptions.ts
import gql from 'graphql-tag';

export const QR_PAYMENT_LINK_STATUS_SUBSCRIPTION = gql`
  subscription QrPaymentLinkStatus($id: uuid!) {
    qr_payment_links_by_pk(id: $id) {
      id
      status
      payment_id
      expires_at
      updated_at
    }
  }
`;
