// c:\Users\fire2\Desktop\HoneyComb\Blum\apos-suite\packages\apps\core\src\views\Public\OrderingSystem\Redesign\Common\QrPayment\QrCodeDisplay.tsx
import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, makeStyles } from '@material-ui/core';
import QRCode from 'qrcode.react';
import { useSubscription } from '@apollo/client';
import { createQrPaymentLink } from '../../../Menu/queries';
import { QR_PAYMENT_LINK_STATUS_SUBSCRIPTION } from '../../../Menu/subscriptions';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    maxWidth: 400,
    margin: '0 auto',
  },
  qrCode: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  timer: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  success: {
    color: theme.palette.success.main,
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  expired: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  instructions: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    fontSize: '0.9rem',
  },
}));

interface QrCodeDisplayProps {
  cart: any;
  orderDetails: {
    orderType: 'dinein';
    customer_name?: string;
    customer_phone?: string;
    total_amount: number;
    tableNumber?: number;
    covers?: number;
  };
  totalPrice: number;
  location_id: string;
  organisation_id: string;
  onPaymentComplete: () => void;
  onError?: (error: string) => void;
}

const QrCodeDisplay: React.FC<QrCodeDisplayProps> = ({ cart, orderDetails, totalPrice, location_id, organisation_id, onPaymentComplete, onError }) => {
  const classes = useStyles();
  const [qrData, setQrData] = useState<{ id: string; paymentUrl: string; expiresAt: string } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  // Create QR payment link
  useEffect(() => {
    const createQrLink = async () => {
      try {
        setLoading(true);

        // Validate order type - QR payments are only for dine-in orders
        if (orderDetails.orderType !== 'dinein') {
          const errorMessage = 'QR payments are only available for dine-in orders';
          setError(errorMessage);
          if (onError) onError(errorMessage);
          return;
        }

        const response = await createQrPaymentLink({
          cartData: cart,
          orderDetails,
          locationId: location_id,
          organisationId: organisation_id,
          base_url: window.location.origin,
        });

        setQrData(response);
        const expiresAt = new Date(response.expiresAt).getTime();
        const now = new Date().getTime();
        setTimeLeft(Math.floor((expiresAt - now) / 1000));
      } catch (err) {
        console.error('Error creating QR payment link:', err);
        const errorMessage = 'Failed to generate QR code. Please try again.';
        setError(errorMessage);
        if (onError) onError(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    createQrLink();
  }, [cart, orderDetails, location_id, organisation_id, onError]);

  // Countdown timer
  useEffect(() => {
    if (timeLeft <= 0) return;

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  // Format time left as MM:SS
  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Subscribe to payment status updates
  const { data: subscriptionData } = useSubscription(QR_PAYMENT_LINK_STATUS_SUBSCRIPTION, {
    variables: { id: qrData?.id },
    skip: !qrData?.id,
  });

  // Handle payment status changes
  useEffect(() => {
    if (subscriptionData?.qr_payment_links_by_pk) {
      const { status } = subscriptionData.qr_payment_links_by_pk;

      if (status === 'completed') {
        onPaymentComplete();
      } else if (status === 'expired' && timeLeft > 0) {
        setTimeLeft(0);
      }
    }
  }, [subscriptionData, onPaymentComplete, timeLeft]);

  if (loading) {
    return (
      <Box className={classes.container}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: 16 }}>
          Generating QR code...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.container}>
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  const paymentStatus = subscriptionData?.qr_payment_links_by_pk?.status || 'pending';

  return (
    <Box className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        Scan to Pay
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Total: £{totalPrice.toFixed(2)}
      </Typography>

      {paymentStatus === 'pending' && timeLeft > 0 && (
        <>
          <Box className={classes.qrCode}>
            <QRCode value={qrData?.paymentUrl || ''} size={200} />
          </Box>
          <Typography variant="body2" className={classes.instructions}>
            Scan this QR code with your phone camera to pay
          </Typography>
          <Typography variant="body1" className={classes.timer}>
            Expires in: {formatTimeLeft()}
          </Typography>
        </>
      )}

      {paymentStatus === 'completed' && (
        <Typography variant="body1" className={classes.success}>
          Payment completed successfully!
        </Typography>
      )}

      {(paymentStatus === 'expired' || timeLeft === 0) && (
        <Typography variant="body1" className={classes.expired}>
          QR code has expired. Please try again.
        </Typography>
      )}
    </Box>
  );
};

export default QrCodeDisplay;
