import React, { useEffect, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField, withStyles } from '@material-ui/core';

import { CategoryItemReducerAction, Modifier, modifierValidation as validation, ModifierValidationErrors, ValidationErrors } from '../..';
import ModifiersTransferList, { ModifierTransferListItem } from '../../../../../ModifiersTransferList';
import { useQuery } from '@apollo/client';
import { 
  getDishModifiersQuery, 
  getDrinkModifiersQuery, 
  getDishCategoryModifiersQuery,
  getDrinkCategoryModifiersQuery
} from './queries';
import { v4 as uuidv4 } from 'uuid';
import { getCurrencySymbol } from '../../../../../../utils/stringUtils';

import styles from './styles';

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  modifiers: Modifier[];
  category_type: string;
  organisationId: string;
  currency: string;
  locale: string;
  taxRate: number;
  errors: ValidationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors>>;
  dispatch: React.Dispatch<{ type: CategoryItemReducerAction; value: any }>;
  category_id: string;
}

interface CategoryModifierAssignment {
  id: string;
  category_id: string;
  modifier_id: string;
  modifier: {
    id: string;
    name: string;
    price: number;
    tax: number;
  };
}

const StepModifiers = ({ 
  classes, 
  modifiers, 
  currency, 
  locale, 
  taxRate, 
  category_type, 
  organisationId, 
  errors, 
  setErrors, 
  dispatch,
  category_id 
}: Props): React.ReactElement => {
  const [allModifiers, setAllModifiers] = useState<ModifierTransferListItem[]>([]);
  const [availableModifiers, setAvailableModifiers] = useState<ModifierTransferListItem[]>([]);
  const [assignedModifiers, setAssignedModifiers] = useState<ModifierTransferListItem[]>([]);
  const [categoryModifiers, setCategoryModifiers] = useState<ModifierTransferListItem[]>([]);
  const [isAddModifierDialogOpen, setIsAddModifierDialogOpen] = useState<boolean>(false);
  const [newModifier, setNewModifier] = useState<Modifier>({
    id: '',
    name: '',
    price: 0,
    tax: taxRate,
  });
  const [modifierErrors, setModifierErrors] = useState<ModifierValidationErrors>({
    name: false,
    price: false,
    tax: false,
  });

  // Fetch regular modifiers
  const { data: dishModifiersData } = useQuery(getDishModifiersQuery, {
    variables: { organisationId },
    fetchPolicy: 'no-cache',
    skip: category_type !== 'dish',
  });

  const { data: drinkModifiersData } = useQuery(getDrinkModifiersQuery, {
    variables: { organisationId },
    fetchPolicy: 'no-cache',
    skip: category_type !== 'drink',
  });

  // Fetch category modifiers
  const { data: dishCategoryModifiersData } = useQuery(getDishCategoryModifiersQuery, {
    variables: { categoryId: category_id },
    fetchPolicy: 'no-cache',
    skip: category_type !== 'dish' || !category_id,
  });

  const { data: drinkCategoryModifiersData } = useQuery(getDrinkCategoryModifiersQuery, {
    variables: { categoryId: category_id },
    fetchPolicy: 'no-cache',
    skip: category_type !== 'drink' || !category_id,
  });

  // Load regular modifiers
  useEffect(() => {
    if (dishModifiersData && category_type === 'dish') {
      const dishModifiers = dishModifiersData.dish_modifiers || [];
      
      // Filter out duplicates based on name, price, and tax
      const uniqueModifiers = dishModifiers.reduce((acc: any[], current: any) => {
        const isDuplicate = acc.some((item) => item.name === current.name && item.price === current.price && item.tax === current.tax);

        if (!isDuplicate) {
          acc.push(current);
        }

        return acc;
      }, []);

      // Map to ModifierTransferListItem format
      const mappedModifiers = uniqueModifiers.map((modifier: any) => ({
        id: modifier.id,
        label: modifier.name,
        price: modifier.price,
        tax: modifier.tax,
      }));
      
      setAllModifiers(mappedModifiers);
    } else if (drinkModifiersData && category_type === 'drink') {
      const drinkModifiers = drinkModifiersData.drink_modifiers || [];
      
      // Filter out duplicates based on name, price, and tax
      const uniqueModifiers = drinkModifiers.reduce((acc: any[], current: any) => {
        const isDuplicate = acc.some((item) => item.name === current.name && item.price === current.price && item.tax === current.tax);

        if (!isDuplicate) {
          acc.push(current);
        }

        return acc;
      }, []);

      // Map to ModifierTransferListItem format
      const mappedModifiers = uniqueModifiers.map((modifier: any) => ({
        id: modifier.id,
        label: modifier.name,
        price: modifier.price,
        tax: modifier.tax,
      }));
      
      setAllModifiers(mappedModifiers);
    }
  }, [dishModifiersData, drinkModifiersData, category_type]);

  // Load category modifiers
  useEffect(() => {
    if (dishCategoryModifiersData && category_type === 'dish') {
      const assignments = dishCategoryModifiersData.dish_category_modifier_assignments || [];
      const mappedModifiers = assignments.map((assignment: CategoryModifierAssignment) => ({
        id: assignment.modifier.id,
        label: assignment.modifier.name,
        price: assignment.modifier.price,
        tax: assignment.modifier.tax,
        isCategoryModifier: true,
        assignmentId: assignment.id,
      }));
      
      setCategoryModifiers(mappedModifiers);
    } else if (drinkCategoryModifiersData && category_type === 'drink') {
      const assignments = drinkCategoryModifiersData.drink_category_modifier_assignments || [];
      const mappedModifiers = assignments.map((assignment: CategoryModifierAssignment) => ({
        id: assignment.modifier.id,
        label: assignment.modifier.name,
        price: assignment.modifier.price,
        tax: assignment.modifier.tax,
        isCategoryModifier: true,
        assignmentId: assignment.id,
      }));
      
      setCategoryModifiers(mappedModifiers);
    }
  }, [dishCategoryModifiersData, drinkCategoryModifiersData, category_type]);

  // Combine regular and category modifiers for the available modifiers list
  useEffect(() => {
    // Create a combined list with both regular and category modifiers
    const combinedModifiers = [...allModifiers];
    
    // Add category modifiers to the list if they don't already exist
    categoryModifiers.forEach(categoryModifier => {
      const exists = combinedModifiers.some(
        modifier => 
          modifier.label === categoryModifier.label && 
          modifier.price === categoryModifier.price && 
          modifier.tax === categoryModifier.tax
      );
      
      if (!exists) {
        combinedModifiers.push(categoryModifier);
      } else {
        // If the modifier already exists, update it to mark as category modifier
        const index = combinedModifiers.findIndex(
          modifier => 
            modifier.label === categoryModifier.label && 
            modifier.price === categoryModifier.price && 
            modifier.tax === categoryModifier.tax
        );
        
        if (index !== -1) {
          combinedModifiers[index] = {
            ...combinedModifiers[index],
            isCategoryModifier: true,
            assignmentId: categoryModifier.assignmentId
          };
        }
      }
    });
    
    setAllModifiers(combinedModifiers);
  }, [categoryModifiers]);

  // Update available and assigned modifiers
  useEffect(() => {
    // Map existing modifiers to ModifierTransferListItem format
    const mappedAssignedModifiers = modifiers.map((modifier) => ({
      id: modifier.id,
      label: modifier.name,
      price: modifier.price,
      tax: modifier.tax,
      // Check if this is a category modifier
      isCategoryModifier: categoryModifiers.some(
        cm => cm.label === modifier.name && cm.price === modifier.price && cm.tax === modifier.tax
      )
    }));

    // Check if category modifiers are already in assigned modifiers
    const categoryModifiersToAdd = categoryModifiers.filter(categoryModifier => 
      !mappedAssignedModifiers.some(
        assignedModifier => 
          assignedModifier.label === categoryModifier.label && 
          assignedModifier.price === categoryModifier.price && 
          assignedModifier.tax === categoryModifier.tax
      )
    );

    // Combine existing modifiers with category modifiers
    const updatedAssignedModifiers = [...mappedAssignedModifiers, ...categoryModifiersToAdd];
    
    // Update the assigned modifiers
    setAssignedModifiers(updatedAssignedModifiers);
    
    // If this is the first load and we've added category modifiers, update the parent component
    if (categoryModifiersToAdd.length > 0 && modifiers.length === mappedAssignedModifiers.length) {
      const newModifiers = updatedAssignedModifiers.map(item => ({
        id: item.id,
        name: item.label,
        price: item.price,
        tax: item.tax,
      }));
      
      dispatch({
        type: CategoryItemReducerAction.UPDATE_MODIFIER,
        value: newModifiers,
      });
    }

    // Filter out assigned modifiers from all modifiers to get available modifiers
    const filteredAvailableModifiers = allModifiers.filter(availableModifier => 
      !updatedAssignedModifiers.some(
        assignedModifier => 
          assignedModifier.label === availableModifier.label && 
          assignedModifier.price === availableModifier.price && 
          assignedModifier.tax === availableModifier.tax
      )
    );
    
    setAvailableModifiers(filteredAvailableModifiers);
  }, [modifiers, categoryModifiers, allModifiers, dispatch]);

  const handleModifierAssignment = (left: ModifierTransferListItem[], right: ModifierTransferListItem[]) => {
    // Convert ModifierTransferListItem back to Modifier format
    const newModifiers = right.map((item) => ({
      id: item.id,
      name: item.label,
      price: item.price,
      tax: item.tax,
    }));

    // Update the modifiers in the parent component
    dispatch({
      type: CategoryItemReducerAction.UPDATE_MODIFIER,
      value: newModifiers,
    });
  };

  const validateModifierField = (field: string) => {
    const errorItems: Record<string, any> = {};
    switch (field) {
      case 'name':
        errorItems.name = validation.name(newModifier.name);
        break;
      case 'price':
        errorItems.price = validation.price(newModifier.price);
        break;
      case 'tax':
        errorItems.tax = validation.tax(newModifier.tax);
        break;
      default:
        break;
    }
    setModifierErrors({ ...modifierErrors, ...errorItems });
    return Object.values(errorItems).some((error) => error !== false);
  };

  const handleAddModifier = () => {
    // Validate all fields
    const hasNameError = validateModifierField('name');
    const hasPriceError = validateModifierField('price');
    const hasTaxError = validateModifierField('tax');

    if (hasNameError || hasPriceError || hasTaxError) {
      return;
    }

    // Create a new modifier with a unique ID
    const modifierWithId = {
      ...newModifier,
      id: uuidv4(),
    };

    // Add the new modifier to the assigned modifiers
    const updatedModifiers = [...modifiers, modifierWithId];
    dispatch({ type: CategoryItemReducerAction.UPDATE_MODIFIER, value: updatedModifiers });

    // Reset the new modifier form
    setNewModifier({
      id: '',
      name: '',
      price: 0,
      tax: taxRate,
    });

    // Close the dialog
    setIsAddModifierDialogOpen(false);
  };

  const handleModifierChange = (field: string, value: any) => {
    let processedValue = value;

    if (field === 'price') {
      processedValue = Math.round(+value * 100);
    } else if (field === 'tax') {
      processedValue = Math.round(+value * 10);
    }

    setNewModifier({
      ...newModifier,
      [field]: processedValue,
    });
  };

  return (
    <>
      <ModifiersTransferList
        labelLeft="Available modifiers"
        labelRight="Assigned modifiers"
        description="Select modifiers to assign to this item"
        allItems={allModifiers}
        currentItems={assignedModifiers}
        availableItems={availableModifiers}
        handleTransfer={handleModifierAssignment}
        currency={currency}
        locale={locale}
        preselectedCategoryModifiers={true}
      />

      <div className={classes.actions}>
        <Button variant="outlined" color="primary" onClick={() => setIsAddModifierDialogOpen(true)}>
          Create New Modifier
        </Button>
      </div>

      <Dialog open={isAddModifierDialogOpen} onClose={() => setIsAddModifierDialogOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Modifier</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={newModifier.name}
            onChange={(e) => handleModifierChange('name', e.target.value)}
            onBlur={() => validateModifierField('name')}
            error={!!modifierErrors.name}
            helperText={modifierErrors.name || ''}
          />
          <TextField
            margin="dense"
            id="price"
            label="Price"
            type="number"
            fullWidth
            value={(newModifier.price / 100).toFixed(2)}
            onChange={(e) => handleModifierChange('price', e.target.value)}
            onBlur={() => validateModifierField('price')}
            error={!!modifierErrors.price}
            helperText={modifierErrors.price || ''}
            InputProps={{
              startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency, locale)}</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            id="tax"
            label="Tax (%)"
            type="number"
            fullWidth
            value={(newModifier.tax / 10).toFixed(1)}
            onChange={(e) => handleModifierChange('tax', e.target.value)}
            onBlur={() => validateModifierField('tax')}
            error={!!modifierErrors.tax}
            helperText={modifierErrors.tax || ''}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsAddModifierDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddModifier} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(StepModifiers);
