import React from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { withStyles, Chip, Typography, Tooltip, Grid } from '@material-ui/core';
import { Help as InfoIcon } from '@material-ui/icons';

import ReadOnlyBlock from '../../../../../ReadOnlyBlock';
import ColourPickerItem from '../../../../../Global/ColourPicker/item';
import { booleanToYesNo, capitaliseFirst, numberToMoney } from '../../../../../../utils/stringUtils';
import { createPriceTaxString } from '../../../../../../utils/conversions';
import { Category } from '../..';

import styles from './styles';

interface CategoryModifier {
  id: string;
  name: string;
  price: number;
  tax: number;
}

interface Props extends WithStyles<typeof styles> {
  classes: ClassNameMap<string>;
  category: Category;
  category_type: string;
  modifiers?: CategoryModifier[];
  currency?: string;
  locale?: string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const StepReview = ({ classes, category, modifiers = [], currency = 'USD', locale = 'en-US', setStep, category_type }: Props): React.ReactElement => {
  const renderModifierChips = () => {
    if (!modifiers || modifiers.length === 0) {
      return (
        <Typography className={classes.chipLabel} variant="subtitle2">
          No modifiers
        </Typography>
      );
    }

    return (
      <div className={classes.chips}>
        {modifiers.map((modifier: CategoryModifier) => {
          const { id, name, price, tax } = modifier;
          const mPrice = numberToMoney(price, currency, locale, true);
          const mTax = createPriceTaxString(price, tax, currency, locale, true);
          return (
            <Tooltip key={id} title={`${mPrice} ~ ${tax / 10}% tax = ${mTax}`} arrow placement="top">
              <Chip className={classes.chip} deleteIcon={<InfoIcon />} size="small" variant="outlined" label={name} />
            </Tooltip>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <ReadOnlyBlock
        gridSize={6}
        title={category.name}
        items={[
          { label: 'Active', value: booleanToYesNo(category.active) },
          { label: 'Colour', components: [category.colour_id ? <ColourPickerItem small colour_id={category.colour_id} /> : null] },
          { label: 'Score', value: category.score?.toString() },
        ]}
        handleEdit={() => setStep(0)}
      />

      <ReadOnlyBlock
        gridSize={6}
        title={`${capitaliseFirst(category_type)} Category`}
        items={[{ label: 'Modifiers', components: [renderModifierChips()] }]}
        handleEdit={() => setStep(1)}
      />
    </>
  );
};

export default withStyles(styles)(StepReview);
