import React, { useEffect, useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { categoryReducer, CategoryReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { updateCategory, updateCategoryDishes, updateCategoryDrinks, updateCategoryModifiers } from './mutations';

import styles from './styles';
import { editCategoryInitQuery } from './queries';
import { useQuery } from '@apollo/client';
import { UnknownObject } from '../../../../react-app-env';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  category_id: string;
  organisation_id: string;
  handleFinish: (id: string) => void;
  category_type: string;
}

const EditCategory = ({ classes, history, category_id, handleFinish, organisation_id, category_type }: Props): React.ReactElement => {
  const [category, dispatchCategory] = useReducer(categoryReducer, {
    name: '',
    colour_id: '',
    active: true,
    score: 0,
    modifiers: [], // Add modifiers to the category state
  });

  const [saving, setSaving] = useState<boolean>(false);
  const [originalModifiers, setOriginalModifiers] = useState<any[]>([]);

  const { data: editCategoryInitData } = useQuery(editCategoryInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editCategoryInitData) {
      console.log('HERE');
      dispatchCategory({ type: CategoryReducerAction.INIT, value: editCategoryInitData.categories_by_pk });

      // Set original modifiers if they exist in the data
      if (category_type === 'dish' && editCategoryInitData.categories_by_pk.dish_category_modifier_assignments) {
        setOriginalModifiers(editCategoryInitData.categories_by_pk.dish_category_modifier_assignments);
      } else if (category_type === 'drink' && editCategoryInitData.categories_by_pk.drink_category_modifier_assignments) {
        setOriginalModifiers(editCategoryInitData.categories_by_pk.drink_category_modifier_assignments);
      }
    }
    return () => {
      mounted = false;
    };
  }, [editCategoryInitData, category_type]);

  const handleSave = async () => {
    setSaving(true);

    // Extract modifiers from category state
    const { modifiers, ...categoryData } = category;

    const updateCategoryVariables = {
      pk_columns: {
        id: category_id,
      },
      set: categoryData,
    };

    // Update the category first
    await updateCategory(updateCategoryVariables);

    // Update items in the category with the score
    const whereCondition = {
      category_assignments: {
        category: {
          id: {
            _eq: category_id,
          },
        },
      },
    };

    const setValues = {
      score: category.score,
    };

    if (category_type === 'dish') {
      await updateCategoryDishes({ where: whereCondition, set: setValues });
    } else {
      await updateCategoryDrinks({ where: whereCondition, set: setValues });
    }
    console.log(modifiers, category, 'AAA');
    // Handle modifiers if they exist in the state
    if (modifiers && modifiers.length > 0) {
      // Process modifiers similar to CategoryItem Edit component
      const modifiersToAdd = modifiers.filter((mod: any) => !originalModifiers.some((orig: any) => orig.modifier.id === mod.id));

      const modifiersToRemove = originalModifiers.filter((orig: any) => !modifiers.some((mod: any) => mod.id === orig.modifier.id)).map((orig: any) => orig.id);

      // Update category modifiers
      await updateCategoryModifiers({
        categoryId: category_id,
        categoryType: category_type,
        organisationId: organisation_id,
        modifiersToAdd: modifiersToAdd.map((mod: any) => ({
          modifier_id: mod.id,
          category_id: category_id,
          organisation_id: organisation_id,
        })),
        modifiersToRemove: modifiersToRemove,
        applyToItems: true, // Apply modifiers to all items in the category
      });
    }

    setSaving(false);
    handleFinish(category_id);
  };

  return (
    <>
      <Steps
        category_type={category_type}
        category_id={category_id}
        organisation_id={organisation_id}
        completeLabel="Save"
        category={category}
        dispatch={dispatchCategory}
        handleSave={handleSave}
      />
      {saving && <CustomBackdrop label="Saving Category" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditCategory));
