import React, { useCallback, useEffect, useState } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Box, Button, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Switch, TextField, Typography } from '@material-ui/core';
import {
  DeliverectIntegration,
  LocationIntegrationReducerAction,
  PaymentIntegration,
  PaymentOnlineIntegration,
  PaymentOnlineProvider,
  PaymentProvider,
  validationIntegration,
  ValidationIntegrationErrors,
  XeroIntegration,
} from '../..';
import SellerOnboardingLightbox from './components/PayPalLink';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
    },
    sectionTitle: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      color: theme.palette.text.primary,
    },
    sectionDescription: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
      marginBottom: theme.spacing(3),
    },
    inputField: {
      width: '100%',
      marginBottom: theme.spacing(2),
      '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    helperText: {
      color: '#6B7280',
      backgroundColor: 'transparent',
    },
    divider: {
      margin: theme.spacing(2, 0),
    },
    switch: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    active: {
      marginBottom: theme.spacing(3),
    },
    stickyButtonContainer: {
      position: 'fixed',
      bottom: theme.spacing(8),
      right: theme.spacing(8),
      zIndex: 1000,
    },
    stickyButton: {
      paddingInline: theme.spacing(4),
    },
  });

interface Props extends WithStyles<typeof styles> {
  payment: PaymentIntegration;
  onlinePayment: PaymentOnlineIntegration;
  deliverect: DeliverectIntegration;
  xero: XeroIntegration;
  vouchersActive: boolean;
  errors: ValidationIntegrationErrors;
  setErrors: React.Dispatch<React.SetStateAction<ValidationIntegrationErrors>>;
  dispatch: React.Dispatch<{ type: LocationIntegrationReducerAction; value: any }>;
  handleSave?: () => void;
  location_id: string;
  organisation_id: string;
  logo: string;
  validateIntegration: any;
}

const IntegrationSettings = ({ classes, payment, onlinePayment, deliverect, xero, vouchersActive, errors, setErrors, dispatch, handleSave, location_id, logo }: Props): React.ReactElement => {
  const [initialState, setInitialState] = useState<{
    payment: PaymentIntegration;
    onlinePayment: PaymentOnlineIntegration;
    deliverect: DeliverectIntegration;
    xero: XeroIntegration;
    vouchersActive: boolean;
  } | null>(null);
  const [stateChanged, setStateChanged] = useState(false);

  useEffect(() => {
    if (!stateChanged) setInitialState({ payment, onlinePayment, deliverect, xero, vouchersActive });
    // eslint-disable-next-line
  }, [stateChanged]);

  useEffect(() => {
    if (
      initialState &&
      JSON.stringify(initialState) !==
        JSON.stringify({
          payment,
          onlinePayment,
          deliverect,
          xero,
          vouchersActive,
        })
    ) {
      setStateChanged(true);
    }
  }, [payment, onlinePayment, deliverect, xero, vouchersActive, initialState]);

  const handlePaymentActiveChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: LocationIntegrationReducerAction.PAYMENT_ACTIVE,
        value: event.target.checked,
      });
    },
    [dispatch]
  );

  const handleOnlinePaymentActiveChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: LocationIntegrationReducerAction.ONLINE_PAYMENT_ACTIVE,
        value: event.target.checked,
      });
    },
    [dispatch]
  );

  const handlePaymentProviderChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      dispatch({
        type: LocationIntegrationReducerAction.PAYMENT_PROVIDER,
        value: event.target.value,
      });
    },
    [dispatch]
  );

  const validate = useCallback(
    (field: string, section: 'payment' | 'deliverect' | 'xero') => {
      const errorItems: { [key: string]: string } = {};
      if (section === 'payment') {
        if (field === 'accountId') {
          errorItems.paymentAccountId = validationIntegration.paymentAccountId(payment.accountId) as string;
        }
        if (field === 'provider') {
          errorItems.paymentProvider = validationIntegration.paymentProvider(payment.provider) as string;
        }
        if (field === 'username') {
          errorItems.paymentUsername = validationIntegration.paymentUsername(payment.username) as string;
        }
        if (field === 'password') {
          errorItems.paymentPassword = validationIntegration.paymentPassword(payment.password) as string;
        }
      } else if (section === 'deliverect') {
        if (field === 'accountId') {
          errorItems.accountId = validationIntegration.deliverectAccountId(deliverect.accountId) as string;
        }
        if (field === 'locationId') {
          errorItems.locationId = validationIntegration.deliverectLocationId(deliverect.locationId) as string;
        }
      }
      setErrors({ ...errors, ...errorItems });
    },
    [payment, deliverect, errors, setErrors]
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.sectionTitle}>
            In-store Payment Provider
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Configure your in-store payment provider for physical transactions
          </Typography>
          <FormControlLabel
            className={classes.active}
            control={<Switch checked={payment.active} onChange={handlePaymentActiveChange} color="primary" />}
            label="Enable In-store Payments"
          />
          {payment.active && (
            <FormControl className={classes.inputField}>
              <InputLabel>Payment Provider</InputLabel>
              <Select value={payment.provider || ''} onChange={handlePaymentProviderChange}>
                <MenuItem value={PaymentProvider.DOJO}>Dojo</MenuItem>
                <MenuItem value={PaymentProvider.ZETTLE}>Zettle</MenuItem>
              </Select>
            </FormControl>
          )}
          {payment.active && [PaymentProvider.DOJO, PaymentProvider.ZETTLE].includes(payment.provider as PaymentProvider) && (
            <>
              <Divider className={classes.divider} />
              <TextField
                className={classes.inputField}
                data-qa="accountId-textfield"
                required
                label="Account ID"
                variant="outlined"
                error={errors.paymentAccountId as boolean}
                helperText={errors.paymentAccountId}
                value={payment.accountId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.PAYMENT_ACCOUNT_ID,
                    value: event.target.value,
                  })
                }
                onKeyUp={() => validate('accountId', 'payment')}
                onBlur={() => validate('accountId', 'payment')}
              />
            </>
          )}
          {payment.active && [PaymentProvider.ZETTLE].includes(payment.provider as PaymentProvider) && (
            <>
              <FormControlLabel
                label="Enable tipping"
                className={classes.active}
                control={
                  <Switch
                    checked={payment.enableTipping}
                    color="primary"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch({
                        type: LocationIntegrationReducerAction.PAYMENT_TIPPING,
                        value: event.target.checked,
                      })
                    }
                  />
                }
              />
            </>
          )}
          {payment.active && [PaymentProvider.DOJO].includes(payment.provider as PaymentProvider) && (
            <>
              <TextField
                className={classes.inputField}
                data-qa="username-textfield"
                required
                label="Username"
                variant="outlined"
                error={errors.paymentUsername as boolean}
                helperText={errors.paymentUsername}
                value={payment.username}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.PAYMENT_USERNAME,
                    value: event.target.value,
                  })
                }
                onKeyUp={() => validate('username', 'payment')}
                onBlur={() => validate('username', 'payment')}
              />
              <TextField
                className={classes.inputField}
                data-qa="password-textfield"
                required
                label="Password"
                variant="outlined"
                error={errors.paymentPassword as boolean}
                helperText={errors.paymentPassword}
                value={payment.password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.PAYMENT_PASSWORD,
                    value: event.target.value,
                  })
                }
                onKeyUp={() => validate('password', 'payment')}
                onBlur={() => validate('password', 'payment')}
              />
            </>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Online Payment Provider
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            Configure your online payment provider for web transactions
          </Typography>
          <FormControlLabel
            className={classes.active}
            control={<Switch checked={onlinePayment.active} onChange={handleOnlinePaymentActiveChange} color="primary" />}
            label="Enable Online Payments"
          />
          {onlinePayment.active && (
            <>
              <FormControl className={classes.inputField}>
                <InputLabel>Payment Provider</InputLabel>
                <Select
                  value={PaymentOnlineProvider.PAYPAL}
                  disabled
                >
                  <MenuItem value={PaymentOnlineProvider.PAYPAL}>PayPal</MenuItem>
                </Select>
              </FormControl>
              <SellerOnboardingLightbox
                handleSave={handleSave!}
                dispatch={dispatch}
                payment={onlinePayment}
                locationId={location_id}
                partnerLogo={logo}
              />
            </>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Deliverect Integration
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <FormControlLabel
            label="Active"
            className={classes.active}
            control={
              <Switch
                checked={deliverect.active}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.DELIVERECT_ACTIVE,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
          {deliverect.active && (
            <>
              <TextField
                className={classes.inputField}
                data-qa="accountId-textfield"
                required
                label="Account ID"
                variant="outlined"
                error={errors.deliverectAccountId as boolean}
                helperText={errors.deliverectAccountId}
                value={deliverect.accountId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.DELIVERECT_ACCOUNT_ID,
                    value: event.target.value,
                  })
                }
                onKeyUp={() => validate('accountId', 'deliverect')}
                onBlur={() => validate('accountId', 'deliverect')}
              />
              <TextField
                className={classes.inputField}
                data-qa="locationId-textfield"
                required
                label="Location ID"
                variant="outlined"
                error={errors.deliverectLocationId as boolean}
                helperText={errors.deliverectLocationId}
                value={deliverect.locationId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.DELIVERECT_LOCATION_ID,
                    value: event.target.value,
                  })
                }
                onKeyUp={() => validate('locationId', 'deliverect')}
                onBlur={() => validate('locationId', 'deliverect')}
              />
              <FormControlLabel
                label="Auto accept"
                className={classes.active}
                control={
                  <Switch
                    checked={deliverect.autoAccept}
                    color="primary"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch({
                        type: LocationIntegrationReducerAction.DELIVERECT_AUTO_ACCEPT,
                        value: event.target.checked,
                      })
                    }
                  />
                }
              />
            </>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Xero Integration
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <FormControlLabel
            label="Active"
            className={classes.active}
            control={
              <Switch
                checked={xero.active}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.XERO_ACTIVE,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
          {xero.active && xero.code && <Typography>Xero account is authenticated</Typography>}
          {xero.active && !xero.code && <Typography color="error">{errors.xeroCode}</Typography>}
          {xero.active && (
            <Button
              // className={classes.button}
              variant="outlined"
              onClick={() => {
                const webhookBaseUrl = process.env.REACT_APP_WEBHOOK_API_URL || window.location.origin;
                const uri = `${webhookBaseUrl}/webhook-api/xero/login/${location_id}`;
                const win = window.open(uri, '_blank', 'popup=yes,width=500,height=800');
                const timer = setInterval(() => {
                  try {
                    if (win) {
                      const url = win.location.pathname;
                      if (url && url.endsWith('xero/success')) {
                        clearInterval(timer);
                        setTimeout(() => {
                          win?.close();
                          dispatch({ type: LocationIntegrationReducerAction.XERO_ACCOUNT_ID, value: 'success' });
                        }, 2500);
                      }
                      if (url && url.endsWith('xero/unsuccessful')) {
                        clearInterval(timer);
                        setTimeout(() => {
                          win?.close();
                          dispatch({ type: LocationIntegrationReducerAction.XERO_ACCOUNT_ID, value: '' });
                        }, 2500);
                      }
                    }
                  } catch (error) {}
                  if (win && win.closed) {
                    clearInterval(timer);
                  }
                }, 1000);
              }}>
              Xero Login {xero.code ? 'Again' : ''}
            </Button>
          )}
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5" className={classes.sectionTitle}>
          Vouchers Integration
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <FormControlLabel
            label="Active"
            className={classes.active}
            control={
              <Switch
                checked={vouchersActive}
                color="primary"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  dispatch({
                    type: LocationIntegrationReducerAction.VOUCHERS,
                    value: event.target.checked,
                  })
                }
              />
            }
          />
        </Paper>
      </Grid>

      {stateChanged && (
        <Box className={classes.stickyButtonContainer}>
          <Button variant="contained" color="primary" className={classes.stickyButton} onClick={handleSave}>
            Save
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default withStyles(styles)(IntegrationSettings);
