import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import useTheme from '@material-ui/core/styles/useTheme';
import { Email } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    backgroundColor: '#000',
    color: '#fff',
  },
  subHeader: {
    backgroundColor: '#FAF9F6',
    color: '#000',
    padding: theme.spacing(2, 4),
  },
  businessHours: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  contactDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    alignItems: 'flex-start',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hoursCollapse: {
    marginTop: theme.spacing(1),
  },
  preOrderButton: {
    backgroundColor: '#fff',
    color: '#000',
    textTransform: 'none',
    fontWeight: 'bold',
    minWidth: '180px',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(8),
      zIndex: 1,
      // backgroundColor: '#000',
      // color: '#fff',
      // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      // '&:hover': {
      //   backgroundColor: '#333',
      // }
    },
  },
  toggleButton: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    maxHeight: '50px',
    objectFit: 'contain',
  },
  orderTypeDialog: {
    '& .MuiDialog-paper': {
      minWidth: '300px',
    },
  },
  orderTypeOption: {
    margin: theme.spacing(1, 0),
  },
}));

const Header: React.FC<{ locationInfo: any; location_id: string; dispatch: any; state: any }> = ({ locationInfo, location_id, dispatch, state }) => {
  const classes = useStyles();
  const [showHours, setShowHours] = useState(false);
  const [showOrderTypeModal, setShowOrderTypeModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOrderTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'UPDATE_ORDER_DETAILS',
      payload: { orderType: event.target.value as 'delivery' | 'pickup' | 'dinein' },
    });
  };

  const getButtonText = () => {
    switch (state.orderDetails.orderType) {
      case 'delivery':
        return 'Pre-order for Delivery';
      case 'dinein':
        return 'Dine-in';
      default:
        return 'Pre-order for Pickup';
    }
  };

  return (
    <AppBar position="static" className={classes.header} style={{
      backgroundColor: locationInfo.ordering_system_primary_color || '#000',
      color: locationInfo.ordering_system_secondary_color || '#fff',
    }}>
      <Toolbar>
        <img
          src={
            locationInfo.header_image_added
              ? `${process.env.REACT_APP_AWS_ORDERING_SYSTEM_IMAGES_URL}/${location_id}.jpeg`
              : `${process.env.REACT_APP_AWS_IMAGES_URL}/no-image.jpeg`
          }
          alt="Logo"
          className={classes.logo}
        />

        {/* Center: Business Name */}
        <Typography variant={isMobile ? 'h6' : 'h5'} style={{ flexGrow: 1, textAlign: 'center' }}>
          {locationInfo.name}
        </Typography>

        {/* Right Side: Pre-order Button */}
        <Button 
          variant={isMobile ? 'outlined' : 'contained'} 
          className={classes.preOrderButton} 
          onClick={() => setShowOrderTypeModal(true)}
          style={{
            backgroundColor: isMobile ? 'transparent' : locationInfo.ordering_system_secondary_color || '#fff',
            color: isMobile ? locationInfo.ordering_system_secondary_color || '#000' : locationInfo.ordering_system_primary_color || '#000',
            borderColor: isMobile ? locationInfo.ordering_system_secondary_color || '#000' : 'transparent'
          }}
        >
          {getButtonText()}
        </Button>
      </Toolbar>

      {/* Order Type Modal */}
      <Dialog open={showOrderTypeModal} onClose={() => setShowOrderTypeModal(false)} className={classes.orderTypeDialog}>
        <DialogTitle>Select Order Type</DialogTitle>
        <DialogContent>
          <RadioGroup value={state.orderDetails.orderType} onChange={handleOrderTypeChange}>
            <FormControlLabel value="pickup" control={<Radio />} label="Pickup" className={classes.orderTypeOption} />
            <FormControlLabel value="delivery" control={<Radio />} label="Delivery" className={classes.orderTypeOption} />
            <FormControlLabel value="dinein" control={<Radio />} label="Dine-in" className={classes.orderTypeOption} />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowOrderTypeModal(false)} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sub-header */}
      <Box className={classes.subHeader}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <div className={classes.businessHours}>
              <Typography variant="body2">Open all day</Typography>
              <IconButton size="small" onClick={() => setShowHours(!showHours)} className={classes.toggleButton}>
                {showHours ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>
            <Collapse in={showHours} className={classes.hoursCollapse}>
              {locationInfo.ordering_system_open_hours.map((day: any, index: any) => (
                <Typography key={index} variant="body2">
                  {day.day}: {day.open} - {day.close}
                </Typography>
              ))}
            </Collapse>

            {/*{!isMobile && (*/}
            <div className={classes.contactDetails}>
              <div className={classes.contactItem}>
                <Typography variant="body2">📞</Typography>
                <Typography variant="body2">{locationInfo.phone || 'N/A'}</Typography>
              </div>
              <Box className={classes.contactItem}>
                <Email />
                <Typography variant="body2">{locationInfo.email}</Typography>
              </Box>
              {/*<div className={classes.contactItem}>*/}
              {/*  <Typography variant="body2">🌍</Typography>*/}
              {/*  <Typography variant="body2">roguesarnies.uk</Typography>*/}
              {/*</div>*/}
              <div className={classes.contactItem}>
                <Typography variant="body2">📍</Typography>
                <Typography variant="body2">{`${locationInfo.address_line_1}, ${locationInfo.address_city}`}</Typography>
              </div>
            </div>
            {/*)}*/}
          </Grid>

          {/* Right Section: Logo */}
          <Grid item xs={12} md={4} className={classes.logoContainer}>
            <img
              src={
                locationInfo.logo_image_added
                  ? `${process.env.REACT_APP_AWS_ORDERING_SYSTEM_IMAGES_URL}/${location_id}-logo.jpeg`
                  : `${process.env.REACT_APP_AWS_IMAGES_URL}/no-image.jpeg`
              }
              alt="Logo"
              style={{ maxHeight: '140px' }}
              className={classes.logo}
            />
          </Grid>
        </Grid>
      </Box>
    </AppBar>
  );
};

export default Header;
