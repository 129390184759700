import React, { useReducer, useState } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../CustomBackdrop';
import { categoryReducer, CategoryReducerAction } from '../Common';
import Steps from '../Common/components/Steps';

import { createCategory } from './mutations';
import { getCategoryNextOrderIndex } from './queries';
import { updateCategoryModifiers } from '../Edit/mutations';

import styles from './styles';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  menu_id: string;
  category_type: string;
  organisation_id?: string;
  handleFinish: (id: string) => void;
}

const CreateCategory = ({ classes, history, menu_id, category_type, organisation_id, handleFinish }: Props): React.ReactElement => {
  const defaultData = {
    name: '',
    colour_id: '',
    active: true,
    score: 0,
    modifiers: [],
  };
  const [category, dispatchCategory] = useReducer(categoryReducer, { ...defaultData });

  const [saving, setSaving] = useState<boolean>(false);

  const handleSave = async (another: boolean) => {
    setSaving(true);

    try {
      const order_index: number = await getCategoryNextOrderIndex({
        menu_id,
        category_type: category_type.toUpperCase(),
      });

      // Create a copy of the category data without the modifiers field
      const { modifiers, ...categoryData } = category;
      
      const data: UnknownObject = {
        ...categoryData,
        menu_id,
        organisation_id,
        order_index: order_index + 1,
        category_type: category_type.toUpperCase(),
      };

      const insertCategoryVariables = {
        object: data,
      };
      
      const id: string | undefined = await createCategory(insertCategoryVariables);
      
      if (id) {
        // If we have modifiers, add them to the category
        if (modifiers && modifiers.length > 0) {
          const modifiersToAdd = modifiers.map((modifier: any) => ({
            category_id: id,
            modifier_id: modifier.id,
            organisation_id,
          }));
          
          await updateCategoryModifiers({
            categoryId: id,
            categoryType: category_type,
            organisationId: organisation_id || '',
            modifiersToAdd: modifiersToAdd,
            modifiersToRemove: [],
            applyToItems: false, // No items exist yet since this is a new category
          });
        }
        
        setSaving(false);
        if (another) {
          dispatchCategory({ type: CategoryReducerAction.INIT, value: defaultData });
        } else {
          handleFinish(id);
        }
      }
    } catch (error) {
      console.error('Error creating category:', error);
      setSaving(false);
    }
  };

  return (
    <>
      <Steps
        completeLabel="Create"
        category={category}
        organisation_id={organisation_id}
        category_type={category_type}
        dispatch={dispatchCategory}
        handleSave={() => handleSave(false)}
        handleAnother={() => handleSave(true)}
      />
      {saving && <CustomBackdrop label="Creating Category" />}
    </>
  );
};

export default withRouter(withStyles(styles)(CreateCategory));
