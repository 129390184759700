import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';
import { capitaliseFirst, pluralise } from '../../../../../../utils/stringUtils';

import Dashboard from '../../../../../../layouts/Dashboard';
import { EditCategoryItem } from '../../../../../../components/Global/CategoryItem';

import { editCategoryItemInitQuery, CategoryItem } from './queries';

interface Props extends RouteComponentProps {}

interface UrlParams {
  menu_id: string;
  category_type: string;
  category_id: string;
  item_id: string;
}

const EditItemOrg = ({ history }: Props): React.ReactElement => {
  const { menu_id, category_type, category_id, item_id } = useParams<UrlParams>();
  const categoryType = capitaliseFirst(category_type);

  const [categoryItem, setCategoryItem] = useState<CategoryItem>({
    name: '',
    category_assignments: [
      {
        category: {
          name: '',
          organisation: {
            id: '',
            name: '',
            currency: '',
            locale: '',
            tax_rate: 0,
          },
          menu: {
            name: '',
          },
        },
      },
    ],
  });

  const { data: editCategoryItemInitData } = useQuery(editCategoryItemInitQuery(item_id, category_type), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && editCategoryItemInitData) {
      setCategoryItem(editCategoryItemInitData[`${pluralise(category_type, 2)}_by_pk`]);
    }
    return () => {
      mounted = false;
    };
  }, [category_type, editCategoryItemInitData]);

  const handleFinish = () => {
    history.push(`/org/menus/${menu_id}/${category_type}/categories/${category_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Menus', link: `/org/menus` },
          { label: categoryItem.category_assignments[0].category.menu.name, link: `/org/menus/${menu_id}` },
          { label: `${categoryType} Categories` },
          { label: categoryItem.category_assignments[0].category.name, link: `/org/menus/${menu_id}/${category_type}/categories/${category_id}` },
          { label: categoryItem.name },
          { label: 'Edit' },
        ]}>
        {categoryItem.category_assignments[0].category.organisation.tax_rate && (
          <EditCategoryItem
            item_id={item_id}
            category_type={category_type}
            organisation_id={categoryItem.category_assignments[0].category.organisation.id}
            category_id={category_id}
            currency={categoryItem.category_assignments[0].category.organisation.currency}
            locale={categoryItem.category_assignments[0].category.organisation.locale}
            taxRate={categoryItem.category_assignments[0].category.organisation.tax_rate}
            handleFinish={handleFinish}
          />
        )}
      </Dashboard>
    </>
  );
};

export default withRouter(EditItemOrg);
