import React, { useState } from 'react';
import { Box, Card, CardContent, CardMedia, makeStyles, Typography, useTheme } from '@material-ui/core';
import ModalComponent from '../MenuItemModal';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    border: '1px solid #ddd',
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    backgroundColor: (props: { soldOut: boolean }) => '#fff',
    // backgroundColor: (props: { soldOut: boolean }) => (props.soldOut ? '#f0f0f0' : '#fff'),
    opacity: (props: { soldOut: boolean }) => 1,
    // opacity: (props: { soldOut: boolean }) => (props.soldOut ? 0.7 : 1),
    cursor: (props: { soldOut: boolean }) => (props.soldOut ? 'not-allowed' : 'pointer'),
    marginBottom: theme.spacing(2),
    width: '100%',
    height: 140,
  },
  cardContent: {
    flex: 1,
    padding: theme.spacing(1.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardMedia: {
    width: 140,
    height: '100%',
    objectFit: 'cover',
    // filter: (props: { soldOut: boolean }) => (props.soldOut ? 'grayscale(100%) opacity(50%)' : 'none'),
  },
  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5),
  },
  soldOutText: {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '0.85rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
  price: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
}));

interface MenuItem {
  id: string;
  name: string;
  description: string;
  price: number; // Price in cents
  imageUrl?: string;
  soldOut: boolean;
  item_per_day_left: number;
  modifier_assignments: any[]; // Replace with actual type
  allergy_assignments: any[]; // Replace with actual type
}

interface Cart {
  [key: string]: number;
}

interface MenuCardProps {
  item: MenuItem;
  cart: Cart;
  dispatch: React.Dispatch<Action>;
  primary: string | null;
  secondary: string | null;
}

type Action =
  | { type: 'ADD_TO_CART'; payload: { cartKey: string; quantity: number } }
  | {
      type: 'REMOVE_FROM_CART';
      payload: { cartKey: string };
    };

const MenuCard: React.FC<MenuCardProps> = ({ item, cart, dispatch, primary, secondary }) => {
  const { id, name, description, price, imageUrl, item_per_day_left } = item;
  const soldOut = item_per_day_left === 0;
  const theme = useTheme();
  const classes = useStyles({ soldOut });
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    if (!soldOut && checkStockAvailability(id, 1)) {
      setModalOpen(true);
    } else {
      alert('Item is sold out or exceeds available stock.');
    }
  };

  const handleCloseModal = () => setModalOpen(false);

  // Truncate description to a max of 80 characters for long text
  const truncatedDescription = description.length > 40 ? `${description.slice(0, 40)}...` : description;

  // Check stock availability
  const checkStockAvailability = (productId: string, quantity: number): boolean => {
    const itemInCartQuantity = Object.keys(cart).reduce((total, cartKey) => {
      const [cartProductId] = cartKey.split(':');
      if (cartProductId === productId) {
        return total + cart[cartKey];
      }
      return total;
    }, 0);

    const totalQuantity = itemInCartQuantity + quantity;
    return totalQuantity <= item_per_day_left;
  };

  const isLongNameSoldOutNoImage = name.length > 25 && checkStockAvailability(id, 1) && imageUrl == '';

  return (
    <>
      <Card className={classes.card} onClick={handleOpenModal}>
        {/* Image Section */}
        {/*{imageUrl ? (*/}
        {/*  <CardMedia className={classes.cardMedia} image={imageUrl} title={name} />*/}
        {/*) : (*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      width: 120,*/}
        {/*      height: '100%',*/}
        {/*      // backgroundColor: '#f0f0f0',*/}
        {/*    }}></div>*/}
        {/*)}*/}
        {imageUrl !== '' && <CardMedia className={classes.cardMedia} image={imageUrl} title={name} />}

        {/* Content Section */}
        <CardContent className={classes.cardContent}>
          <Box>
            <Typography className={classes.title} style={{ fontSize: isLongNameSoldOutNoImage ? '0.9rem' : '1rem' }}>
              {name}
            </Typography>
            {soldOut && (
              <Typography className={classes.soldOutText} style={{ fontSize: isLongNameSoldOutNoImage ? '0.8rem' : '0.9rem' }}>
                Out of stock
              </Typography>
            )}
            <Typography className={classes.description}>{truncatedDescription}</Typography>
          </Box>
          <Typography className={classes.price}>£{(price / 100).toFixed(2)}</Typography>
        </CardContent>
      </Card>

      {/* Modal for Item Details */}
      {!soldOut && <ModalComponent primary={primary} secondary={secondary} open={modalOpen} onClose={handleCloseModal} item={item} cart={cart} dispatch={dispatch} />}
    </>
  );
};

export default MenuCard;
