import { gql } from '@apollo/client';

export const getDishModifiersQuery = gql`
  query GetDishModifiers($organisationId: uuid!) {
    dish_modifiers(where: { organisation_id: { _eq: $organisationId } }) {
      id
      name
      organisation_id
      price
      tax
    }
  }
`;

export const getDrinkModifiersQuery = gql`
  query GetDrinkModifiers($organisationId: uuid!) {
    drink_modifiers(where: { organisation_id: { _eq: $organisationId } }) {
      id
      name
      organisation_id
      price
      tax
    }
  }
`;

export const getDishCategoryModifierAssignmentsQuery = gql`
  query GetDishCategoryModifierAssignments($categoryId: uuid!) {
    dish_category_modifier_assignments(where: { category_id: { _eq: $categoryId } }) {
      id
      category_id
      modifier_id
      modifier {
        id
        name
        price
        tax
      }
    }
  }
`;

export const getDrinkCategoryModifierAssignmentsQuery = gql`
  query GetDrinkCategoryModifierAssignments($categoryId: uuid!) {
    drink_category_modifier_assignments(where: { category_id: { _eq: $categoryId } }) {
      id
      category_id
      modifier_id
      modifier {
        id
        name
        price
        tax
      }
    }
  }
`;
