import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Container, List, ListItem, ListItemText, makeStyles, Paper, Theme, Typography, useMediaQuery } from '@material-ui/core';
import { useMutation as useRestMutation, useQuery as useRestQuery } from 'react-query';
import { useSubscription } from '@apollo/client';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { capturePayment, createPayment, getMenuQuery, getQrPaymentLink, processQrPayment } from '../Menu/queries';
import { createServedOrderAfterPayment, createServedOrderPayment, createTakeAwayOrderAfterPayment, createTakeAwayOrderPayment } from '../Menu/mutations';
import { QR_PAYMENT_LINK_STATUS_SUBSCRIPTION } from '../Menu/subscriptions';
import PayPalCheckout from '../Common/components/Checkout';
import { getThreeDSecureSettings } from '../../../../components/Global/Location/Common/components/StepIntegrations/components/ThreeDSecureSettings/queries';
import { getPaymentSettings } from '../../../../components/Global/Location/Common/components/StepIntegrations/components/PayPalLink/queries';
import { CategoryType } from '../Common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100vh',
    backgroundColor: '#f5f5f5',
    // padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      // padding: theme.spacing(4),
    },
  },
  container: {
    width: '100%',
    maxWidth: 800,
    margin: '0 auto',
    padding: 0,
  },
  paper: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.5rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      marginBottom: theme.spacing(3),
    },
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      marginBottom: theme.spacing(2),
    },
  },
  loader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(6),
    },
  },
  error: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(2),
  },
  paymentContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  backButton: {
    marginTop: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      width: 'auto',
    },
  },
  totalAmount: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
  },
  customerInfo: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0,0,0,0.03)',
    borderRadius: theme.spacing(1),
  },
  cartContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    border: '1px solid rgba(0,0,0,0.08)',
  },
  cartHeader: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0,0,0,0.08)',
    backgroundColor: 'rgba(0,0,0,0.02)',
    borderRadius: `${theme.spacing(1)}px ${theme.spacing(1)}px 0 0`,
  },
  cartItem: {
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0,0,0,0.08)',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  itemName: {
    fontWeight: 'bold',
  },
  itemQuantity: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '50%',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    fontSize: '0.8rem',
  },
  itemRow: {
    display: 'flex',
    alignItems: 'center',
  },
  modifierList: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
    padding: 0,
  },
  modifierItem: {
    padding: theme.spacing(0.5, 0),
    fontSize: '0.85rem',
    color: theme.palette.text.secondary,
  },
  timer: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '1.1rem',
  },
  expired: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
}));

const { REACT_APP_PAYPAL_CLIENT_ID, REACT_APP_PAYPAL_BN_CODE } = process.env;

interface CartItem {
  id: string;
  name: string;
  quantity: number;
  price: number;
  category: string;
  modifiers: Array<{
    id: string;
    name: string;
    price: number;
  }>;
}

const QRPaymentPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { organisation_id, location_id, payment_id } = useParams<{
    organisation_id: string;
    location_id: string;
    payment_id: string;
  }>();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [paymentData, setPaymentData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [threeDSecureSettings, setThreeDSecureSettings] = useState<any>(null);
  const [paymentSettings, setPaymentSettings] = useState<any>(null);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  // Fetch QR payment link data
  const {
    data: qrPaymentData,
    isLoading: isLoadingQrPayment,
    refetch: refetchQrPayment,
  } = useRestQuery({
    queryKey: ['qr-payment', payment_id],
    queryFn: () => getQrPaymentLink(payment_id),
    onSuccess: (data) => {
      setPaymentData(data);
      if (data.expires_at) {
        const expiresAt = new Date(data.expires_at).getTime();
        const now = new Date().getTime();
        setTimeLeft(Math.max(0, Math.floor((expiresAt - now) / 1000)));
      }
    },
    onError: () => {
      setError('Failed to load payment information. Please try again.');
    },
  });

  const { data: menuData, isLoading: isLoadingMenu } = useRestQuery<any>({
    queryKey: 'menu-data',
    queryFn: () => getMenuQuery(organisation_id, location_id),
  });

  const { data: subscriptionData } = useSubscription(QR_PAYMENT_LINK_STATUS_SUBSCRIPTION, {
    variables: { id: payment_id },
    skip: !payment_id,
  });

  // Payment mutations
  const { mutateAsync: createOrder, isLoading: creatingOrder } = useRestMutation({
    mutationKey: 'create-order',
    mutationFn: (request: string) => createPayment(request),
  });

  const { mutateAsync: captureOrder, isLoading: capturingOrder } = useRestMutation({
    mutationKey: 'capture-order',
    mutationFn: (request: any) => {
      return capturePayment(request.orderID, request.merchantId);
    },
  });

  const { mutateAsync: createServedOrderAfterPaymentAsync, isLoading: creatingOrderAfterPayment } = useRestMutation({
    mutationKey: 'create-order-after-payment',
    mutationFn: (order: any) => createServedOrderAfterPayment(order),
  });

  const { mutateAsync: createTakeAwayOrderAfterPaymentAsync, isLoading: creatingTakeAwayOrderAfterPayment } = useRestMutation({
    mutationKey: 'create-takeaway-order-after-payment',
    mutationFn: (order: any) => createTakeAwayOrderAfterPayment(order),
  });

  const { mutateAsync: createTakeAwayOrderPaymentAsync, isLoading: creatingTakeAwayOrderPayment } = useRestMutation({
    mutationKey: 'create-takeaway-order-payment',
    onSuccess: () => {},
    mutationFn: (order: any) => createTakeAwayOrderPayment(order),
  });

  const { mutateAsync: createServedOrderPaymentAsync, isLoading: creatingServedOrderPayment } = useRestMutation({
    mutationKey: 'create-served-order-payment',
    onSuccess: (data) => {
      processPayment(data.data.insert_order_served_payments_one.id);
    },
    mutationFn: (order: any) => createServedOrderPayment(order),
  });

  const { mutateAsync: processPayment, isLoading: processingPayment } = useRestMutation({
    mutationKey: 'process-payment',
    mutationFn: (paymentId: string) => processQrPayment(payment_id, paymentId),
    onSuccess: () => {
      setIsPaymentCompleted(true);
      refetchQrPayment();
    },
    onError: () => {
      setError('Failed to process payment. Please try again.');
    },
  });

  const isLoading =
    isLoadingQrPayment ||
    creatingOrder ||
    capturingOrder ||
    creatingOrderAfterPayment ||
    creatingTakeAwayOrderAfterPayment ||
    creatingTakeAwayOrderPayment ||
    creatingServedOrderPayment ||
    processingPayment;

  // Fetch 3D Secure and payment settings
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const threeDSecureData = await getThreeDSecureSettings(location_id);
        const paymentData = await getPaymentSettings(location_id);

        setThreeDSecureSettings(threeDSecureData);
        setPaymentSettings(paymentData);
      } catch (error) {
        console.error('Failed to fetch payment settings:', error);
        setError('Failed to load payment settings. Please try again.');
      }
    };

    if (location_id) {
      fetchSettings();
    }
  }, [location_id]);

  // Monitor subscription data for payment status changes
  useEffect(() => {
    if (subscriptionData?.qr_payment_links_by_pk) {
      const { status } = subscriptionData.qr_payment_links_by_pk;

      if (status === 'completed') {
        setIsPaymentCompleted(true);
      }
    }
  }, [subscriptionData]);

  // Countdown timer
  useEffect(() => {
    if (timeLeft <= 0) return;

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  // Format time left as MM:SS
  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // Parse modifiers key
  const parseModifiersKey = (modifiersKey: string): string[] => (modifiersKey ? modifiersKey.split(',') : []);

  // Build cart items for display
  const cartItems = useMemo(() => {
    if (!paymentData?.cart_data || !menuData) return [];

    return Object.keys(paymentData.cart_data)
      .map((cartKey) => {
        const [productId, modifiersKey] = cartKey.split(':');
        const modifiersArray = parseModifiersKey(modifiersKey);

        // Find the product in menu data
        const product = menuData.categories
          .flatMap((category: any) => {
            if (category.category_type === CategoryType.DISH) {
              return category.dish_assignments.map((item: any) => ({
                ...item.dish,
                category_name: category.name,
                category_type: CategoryType.DISH,
              }));
            } else {
              return category.drink_assignments.map((item: any) => ({
                ...item.drink,
                category_name: category.name,
                category_type: CategoryType.DRINK,
              }));
            }
          })
          .find((item: any) => item.id === productId);

        if (!product) return null;

        // Get modifiers
        const modifiers =
          product.modifier_assignments
            ?.filter((mod: any) => modifiersArray.includes(mod.modifier.name))
            .map((mod: any) => ({
              id: mod.modifier.id,
              name: mod.modifier.name,
              price: mod.modifier.price,
            })) || [];

        return {
          id: product.id,
          name: product.name,
          quantity: paymentData.cart_data[cartKey],
          price: product.price,
          category: product.category_name,
          modifiers,
        };
      })
      .filter((item): item is CartItem => item !== null);
  }, [paymentData, menuData]);

  // Calculate total price
  const totalPrice = useMemo(() => {
    if (!cartItems.length) return 0;

    return cartItems.reduce((total: number, item: CartItem) => {
      const itemPrice = item.price;
      const modifiersPrice = item.modifiers.reduce((sum: number, mod) => sum + mod.price, 0);
      return total + (itemPrice + modifiersPrice) * item.quantity;
    }, 0);
  }, [cartItems]);

  // Handle back button click
  const handleBack = () => {
    history.push(`/organisations/${organisation_id}/locations/${location_id}/ordering/menu1`);
  };

  if (isLoading && !paymentData) {
    return (
      <Box className={classes.root}>
        <Container className={classes.container}>
          <Paper className={classes.paper}>
            <Box className={classes.loader}>
              <CircularProgress size={isMobile ? 40 : 48} />
              <Typography variant="body1" style={{ marginTop: 16, textAlign: 'center' }}>
                Loading payment information...
              </Typography>
            </Box>
          </Paper>
        </Container>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.root}>
        <Container className={classes.container}>
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.title}>
              Payment Error
            </Typography>
            <Typography variant="body1" className={classes.error}>
              {error}
            </Typography>
            <Button variant="contained" color="primary" fullWidth={isMobile} className={classes.backButton} onClick={handleBack}>
              Return to Menu
            </Button>
          </Paper>
        </Container>
      </Box>
    );
  }

  if (isPaymentCompleted || (paymentData && paymentData.status === 'completed')) {
    return (
      <Box className={classes.root}>
        <Container className={classes.container}>
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.title}>
              Payment Successful
            </Typography>
            <Typography variant="body1" style={{ marginBottom: isMobile ? 16 : 24 }}>
              Your payment has been successfully processed. Thank you for your order!
            </Typography>

            {(paymentData?.order_details?.customer_name || paymentData?.order_details?.customer_phone) && (
              <Box className={classes.customerInfo}>
                {paymentData?.order_details?.customer_name && (
                  <Typography variant="body2" className={classes.subtitle}>
                    {paymentData.order_details.customer_name}
                  </Typography>
                )}
                {paymentData?.order_details?.customer_phone && (
                  <Typography variant="body2" className={classes.subtitle}>
                    {paymentData.order_details.customer_phone}
                  </Typography>
                )}
                {paymentData?.order_details?.tableNumber && (
                  <Typography variant="body2" className={classes.subtitle}>
                    table: {paymentData.order_details.tableNumber}
                  </Typography>
                )}
              </Box>
            )}

            {cartItems.length > 0 && (
              <Box className={classes.cartContainer}>
                <Box className={classes.cartHeader}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Order Details
                  </Typography>
                </Box>
                {cartItems.map((item, index) => (
                  <Box key={`${item.id}-${index}`} className={classes.cartItem}>
                    <Box className={classes.itemRow}>
                      <Box className={classes.itemQuantity}>{item.quantity}</Box>
                      <Typography className={classes.itemName}>{item.name}</Typography>
                      <Box flexGrow={1} />
                      <Typography>£{((item.price + item.modifiers.reduce((sum: number, mod) => sum + mod.price, 0)) / 100).toFixed(2)}</Typography>
                    </Box>
                    {item.modifiers.length > 0 && (
                      <List dense className={classes.modifierList}>
                        {item.modifiers.map((mod) => (
                          <ListItem key={mod.id} className={classes.modifierItem}>
                            <ListItemText primary={`+ ${mod.name} (£${(mod.price / 100).toFixed(2)})`} />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Box>
                ))}
                <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    Total
                  </Typography>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                    £{(totalPrice / 100).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            )}

            <Button variant="contained" color="primary" fullWidth={isMobile} className={classes.backButton} onClick={handleBack}>
              Return to Menu
            </Button>
          </Paper>
        </Container>
      </Box>
    );
  }

  // Check if payment link is expired
  const isExpired = paymentData && (new Date(paymentData.expires_at) < new Date() || timeLeft === 0);

  if (isExpired) {
    return (
      <Box className={classes.root}>
        <Container className={classes.container}>
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.title}>
              Payment Link Expired
            </Typography>
            <Typography variant="body1" style={{ marginBottom: isMobile ? 16 : 24 }}>
              This payment link has expired. Please return to the menu and try again.
            </Typography>
            <Button variant="contained" color="primary" fullWidth={isMobile} className={classes.backButton} onClick={handleBack}>
              Return to Menu
            </Button>
          </Paper>
        </Container>
      </Box>
    );
  }

  // Get merchant ID from menu data if available
  const merchantId = menuData?.location_info?.integrations?.[0]?.onlinePayment?.merchantId || null;

  if (!isLoadingMenu)
    return (
      <PayPalScriptProvider
        options={{
          clientId: REACT_APP_PAYPAL_CLIENT_ID ?? '',
          ...(merchantId && { merchantId }),
          currency: 'GBP',
          components: 'buttons,card-fields,marks,applepay,googlepay',
          intent: 'capture',
          locale: 'en_GB',
          dataPartnerAttributionId: REACT_APP_PAYPAL_BN_CODE ?? 'AccentPOS_PPCP',
          disableFunding: 'bancontact,blik,sofort,mybank,ideal,giropay,eps,sepa',
        }}
        data-partner-attribution-id={REACT_APP_PAYPAL_BN_CODE ?? 'AccentPOS_PPCP'}>
        <Box className={classes.root}>
          <Container className={classes.container}>
            <Paper className={classes.paper}>
              <Typography variant="h5" className={classes.title}>
                Complete Your Payment
              </Typography>

              {paymentData?.order_details?.total_amount && (
                <Typography variant="body1" className={classes.totalAmount}>
                  Total: £{paymentData.order_details.total_amount}
                </Typography>
              )}

              {timeLeft > 0 && (
                <Typography variant="body1" className={classes.timer}>
                  Time remaining: {formatTimeLeft()}
                </Typography>
              )}

              {(paymentData?.order_details?.customer_name || paymentData?.order_details?.customer_phone) && (
                <Box className={classes.customerInfo}>
                  {paymentData?.order_details?.customer_name && (
                    <Typography variant="body2" className={classes.subtitle}>
                      {paymentData.order_details.customer_name}
                    </Typography>
                  )}
                  {paymentData?.order_details?.customer_phone && (
                    <Typography variant="body2" className={classes.subtitle}>
                      {paymentData.order_details.customer_phone}
                    </Typography>
                  )}
                </Box>
              )}

              {cartItems.length > 0 && (
                <Box className={classes.cartContainer}>
                  <Box className={classes.cartHeader}>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      Order Details
                    </Typography>
                  </Box>
                  {cartItems.map((item, index) => (
                    <Box key={`${item.id}-${index}`} className={classes.cartItem}>
                      <Box className={classes.itemRow}>
                        <Box className={classes.itemQuantity}>{item.quantity}</Box>
                        <Typography className={classes.itemName}>{item.name}</Typography>
                        <Box flexGrow={1} />
                        <Typography>£{((item.price + item.modifiers.reduce((sum: number, mod) => sum + mod.price, 0)) / 100).toFixed(2)}</Typography>
                      </Box>
                      {item.modifiers.length > 0 && (
                        <List dense className={classes.modifierList}>
                          {item.modifiers.map((mod) => (
                            <ListItem key={mod.id} className={classes.modifierItem}>
                              <ListItemText primary={`+ ${mod.name} (£${(mod.price / 100).toFixed(2)})`} />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </Box>
                  ))}
                  <Box p={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      Total
                    </Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                      £{(totalPrice / 100).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              )}

              {paymentData && menuData && threeDSecureSettings && paymentSettings && (
                <Box className={classes.paymentContainer}>
                  <PayPalCheckout
                    threeDSecureSettings={threeDSecureSettings}
                    paymentSettings={paymentSettings}
                    cart={paymentData.cart_data}
                    menuData={menuData}
                    createOrder={createOrder}
                    captureOrder={captureOrder}
                    createServedOrderAfterPaymentAsync={createServedOrderAfterPaymentAsync}
                    createTakeAwayOrderAfterPaymentAsync={createTakeAwayOrderAfterPaymentAsync}
                    createTakeAwayOrderPaymentAsync={createTakeAwayOrderPaymentAsync}
                    createServedOrderPaymentAsync={createServedOrderPaymentAsync}
                    location_id={location_id}
                    organisation_id={organisation_id}
                    orderType={paymentData.order_details?.orderType || 'dinein'}
                    timeOption={paymentData.order_details?.timeOption || 'now'}
                    tableNumber={paymentData.order_details?.tableNumber || null}
                    customerName={paymentData.customer_details?.name || ''}
                    customerPhone={paymentData.customer_details?.phone || ''}
                    customerEmail={paymentData.customer_details?.email || ''}
                    deliveryAddress={paymentData.customer_details?.address || ''}
                    deliveryPostcode={paymentData.customer_details?.postcode || ''}
                    scheduledDay={paymentData.order_details?.scheduledDay || ''}
                    scheduledTime={paymentData.order_details?.scheduledTime || ''}
                    onClose={handleBack}
                    merchantId={merchantId}
                  />
                </Box>
              )}

              <Button variant="outlined" color="primary" fullWidth={isMobile} className={classes.backButton} onClick={handleBack}>
                Cancel and Return to Menu
              </Button>
            </Paper>
          </Container>
        </Box>
      </PayPalScriptProvider>
    );
  return <></>;
};

export default QRPaymentPage;
