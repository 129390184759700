import { gql } from '@apollo/client';
import gqlClient from '../../../../utils/apolloClient';
import {
  insertDishCategoryModifierAssignment,
  insertDishModifierAssignments,
  deleteDishCategoryModifierAssignment,
  insertDrinkCategoryModifierAssignment,
  insertDrinkModifierAssignments,
  deleteDrinkCategoryModifierAssignment,
  getDishesInCategory,
  getDrinksInCategory,
} from '../Common/components/StepModifiers/mutations';

const updateCategoryMutation = gql`
  mutation ($pk_columns: categories_pk_columns_input!, $set: categories_set_input!) {
    update_categories_by_pk(pk_columns: $pk_columns, _set: $set) {
      id
    }
  }
`;

export const updateCategory = async (variables: object) => {
  return await gqlClient.mutate<{
    update_categories_by_pk: {
      id: string;
    };
  }>({ mutation: updateCategoryMutation!, variables });
};

const updateCategoryDrinksMutation = gql`
  mutation ($where: drinks_bool_exp!, $set: drinks_set_input!) {
    update_drinks(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

export const updateCategoryDrinks = async (variables: { where: object; set: object }) => {
  return await gqlClient.mutate<{
    update_drinks: {
      affected_rows: number;
    };
  }>({
    mutation: updateCategoryDrinksMutation,
    variables,
  });
};

const updateCategoryDishesMutation = gql`
  mutation ($where: dishes_bool_exp!, $set: dishes_set_input!) {
    update_dishes(where: $where, _set: $set) {
      affected_rows
    }
  }
`;

export const updateCategoryDishes = async (variables: { where: object; set: object }) => {
  return await gqlClient.mutate<{
    update_dishes: {
      affected_rows: number;
    };
  }>({
    mutation: updateCategoryDishesMutation,
    variables,
  });
};

// Function to handle updating category modifiers and optionally applying them to category items
export const updateCategoryModifiers = async ({
  categoryId,
  categoryType,
  organisationId,
  modifiersToAdd,
  modifiersToRemove,
  applyToItems = false,
}: {
  categoryId: string;
  categoryType: string;
  organisationId: string;
  modifiersToAdd: any[];
  modifiersToRemove: string[];
  applyToItems?: boolean;
}) => {
  try {
    // Add new modifier assignments to the category
    for (const modifierData of modifiersToAdd) {
      if (categoryType === 'dish') {
        await insertDishCategoryModifierAssignment({ object: modifierData });
      } else if (categoryType === 'drink') {
        await insertDrinkCategoryModifierAssignment({ object: modifierData });
      }
    }

    // Remove modifier assignments from the category
    for (const assignmentId of modifiersToRemove) {
      if (categoryType === 'dish') {
        await deleteDishCategoryModifierAssignment({ id: assignmentId });
      } else if (categoryType === 'drink') {
        await deleteDrinkCategoryModifierAssignment({ id: assignmentId });
      }
    }

    // If applyToItems is true, apply the modifiers to all items in the category
    if (applyToItems) {
      // Get all items in the category
      let items: Array<{ id: string; organisation_id: string }> = [];

      if (categoryType === 'dish') {
        const response = await getDishesInCategory({ categoryId });
        items = response.data.dishes;
      } else if (categoryType === 'drink') {
        const response = await getDrinksInCategory({ categoryId });
        items = response.data.drinks;
      }

      if (items.length > 0) {
        // For modifiers to add
        if (modifiersToAdd.length > 0) {
          // Create modifier assignments for each item
          const modifierAssignments = [];

          for (const item of items) {
            for (const modifierData of modifiersToAdd) {
              modifierAssignments.push({
                [categoryType === 'dish' ? 'dish_id' : 'drink_id']: item.id,
                modifier_id: modifierData.modifier_id,
                organisation_id: organisationId,
              });
            }
          }

          // Insert the modifier assignments
          if (modifierAssignments.length > 0) {
            if (categoryType === 'dish') {
              await insertDishModifierAssignments({ objects: modifierAssignments });
            } else if (categoryType === 'drink') {
              await insertDrinkModifierAssignments({ objects: modifierAssignments });
            }
          }
        }

        // For modifiers to remove
        if (modifiersToRemove.length > 0) {
          // First, get the modifier IDs associated with the assignment IDs that are being removed
          const getCategoryAssignmentsMutation = gql`
            query GetCategoryAssignments($ids: [uuid!]) {
              ${categoryType === 'dish' ? 'dish_category_modifier_assignments' : 'drink_category_modifier_assignments'}(
                where: { id: { _in: $ids } }
              ) {
                id
                modifier_id
              }
            }
          `;
          
          const categoryAssignmentsResponse = await gqlClient.query({
            query: getCategoryAssignmentsMutation,
            variables: { ids: modifiersToRemove },
            fetchPolicy: 'no-cache',
          });
          
          const categoryAssignments = categoryType === 'dish' 
            ? categoryAssignmentsResponse.data.dish_category_modifier_assignments 
            : categoryAssignmentsResponse.data.drink_category_modifier_assignments;
          
          const modifierIdsToRemove = categoryAssignments.map((assignment: any) => assignment.modifier_id);
          
          // For each item in the category, remove the modifier assignments for the removed modifiers
          for (const item of items) {
            if (modifierIdsToRemove.length > 0) {
              // Get all modifier assignments for this item that match the modifier IDs to remove
              const getItemModifierAssignmentsQuery = gql`
                query GetItemModifierAssignments($itemId: uuid!, $modifierIds: [uuid!]) {
                  ${categoryType === 'dish' ? 'dish_modifier_assignments' : 'drink_modifier_assignments'}(
                    where: {
                      ${categoryType === 'dish' ? 'dish_id' : 'drink_id'}: { _eq: $itemId },
                      modifier_id: { _in: $modifierIds }
                    }
                  ) {
                    id
                  }
                }
              `;
              
              const itemModifierAssignmentsResponse = await gqlClient.query({
                query: getItemModifierAssignmentsQuery,
                variables: { 
                  itemId: item.id, 
                  modifierIds: modifierIdsToRemove 
                },
                fetchPolicy: 'no-cache',
              });
              
              const itemModifierAssignments = categoryType === 'dish' 
                ? itemModifierAssignmentsResponse.data.dish_modifier_assignments 
                : itemModifierAssignmentsResponse.data.drink_modifier_assignments;
              
              // Delete each assignment
              for (const assignment of itemModifierAssignments) {
                const deleteItemModifierAssignmentMutation = gql`
                  mutation DeleteItemModifierAssignment($id: uuid!) {
                    delete_${categoryType}_modifier_assignments_by_pk(id: $id) {
                      id
                    }
                  }
                `;
                
                await gqlClient.mutate({
                  mutation: deleteItemModifierAssignmentMutation,
                  variables: { id: assignment.id },
                });
              }
            }
          }
        }
      }
    }

    return true;
  } catch (error) {
    console.error('Error updating category modifiers:', error);
    throw error;
  }
};
