import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps, useParams } from 'react-router-dom';

import { capitaliseFirst } from '../../../../../../utils/stringUtils';

import Dashboard from '../../../../../../layouts/Dashboard';
import { CreateCategoryItem } from '../../../../../../components/Global/CategoryItem';

import { createCategoryItemInitQuery, Category } from './queries';

interface Props extends RouteComponentProps {}

interface UrlParams {
  menu_id: string;
  category_type: string;
  category_id: string;
}

const CreateItemAdmin = ({ history }: Props): React.ReactElement => {
  const { menu_id, category_type, category_id } = useParams<UrlParams>();
  const categoryType = capitaliseFirst(category_type);

  const [category, setCategory] = useState<Category>({
    name: '',
    organisation: {
      id: '',
      name: '',
      currency: '',
      locale: '',
      tax_rate: 0,
    },
    menu: {
      name: '',
    },
  });

  const { data: createCategoryItemInitData } = useQuery(createCategoryItemInitQuery(category_id), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    let mounted = true;
    if (mounted && createCategoryItemInitData) {
      const cat = createCategoryItemInitData.categories_by_pk;
      setCategory(cat);
    }
    return () => {
      mounted = false;
    };
  }, [createCategoryItemInitData]);

  const handleFinish = () => {
    history.push(`/org/menus/${menu_id}/${category_type}/categories/${category_id}`);
  };

  return (
    <>
      <Dashboard
        breadcrumbs={[
          { label: 'Menus', link: `/org/menus` },
          { label: category.menu.name, link: `/org/menus/${menu_id}` },
          { label: `${categoryType} Categories` },
          { label: category.name, link: `/org/menus/${menu_id}/${category_type}/categories/${category_id}` },
          { label: `Create ${capitaliseFirst(category_type)}` },
        ]}>
        {category.organisation.tax_rate && (
          <CreateCategoryItem
            category_id={category_id}
            category_type={category_type}
            organisation_id={category.organisation.id}
            currency={category.organisation.currency}
            locale={category.organisation.locale}
            taxRate={category.organisation.tax_rate}
            handleFinish={handleFinish}
          />
        )}
      </Dashboard>
    </>
  );
};

export default withRouter(CreateItemAdmin);
