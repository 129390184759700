import React, { useEffect, useReducer, useState, useContext } from 'react';
import { WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { UnknownObject } from '../../../../react-app-env';

import { useQuery } from '@apollo/client';

import { withStyles } from '@material-ui/core';

import CustomBackdrop from '../../../../components/CustomBackdrop';
import { Allergy, AllergyAssignment, categoryItemReducer, CategoryItemReducerAction, Modifier, ModifierAssignment } from '../Common';
import Steps from '../Common/components/Steps';
import { AuthContext, EnumUserRole } from '../../../../components/AuthProvider';

import {
  updateDish,
  updateDishItemAllergyAssignments,
  updateDishItemModifierAssignments,
  updateDrink,
  updateDrinkItemAllergyAssignments,
  updateDrinkItemModifierAssignments,
} from './mutations';

import { editCategoryItemInitQuery } from './queries';

import styles from './styles';
import { pluralise } from '../../../../utils/stringUtils';
import { webhookClient } from '../../../../utils/webhookClient';

interface Props extends WithStyles<typeof styles>, RouteComponentProps {
  classes: ClassNameMap<string>;
  item_id: string;
  category_id: string;
  category_type: string;
  currency: string;
  locale: string;
  taxRate: number;
  organisation_id?: string;
  handleFinish: (id: string) => void;
}

const EditCategoryItem = ({ classes, item_id, category_id, category_type, currency, locale, taxRate, organisation_id, handleFinish, history }: Props): React.ReactElement => {
  const { userRole } = useContext(AuthContext)!;
  const isAdmin = userRole === EnumUserRole.ADMIN;

  const [categoryItem, dispatchCategoryItem] = useReducer(categoryItemReducer, {
    name: '',
    description: '',
    active: true,
    tag: '',
    price: 0,
    tax: taxRate,
    bypass_prep: false,
    should_print: true,
    auto_serve_when_ready: false,
    prep_time: 0,
    modifiers: [],
    allergies: [],
    image_added: false,
    score: 0,
  });

  const [originalModifierAssignments, setOriginalModifierAssignments] = useState<ModifierAssignment[]>([]);
  const [originalAllergyAssignments, setOriginalAllergyAssignments] = useState<AllergyAssignment[]>([]);

  const [saving, setSaving] = useState<boolean>(false);

  const { data: editCategoryItemInitData } = useQuery(editCategoryItemInitQuery(item_id, category_type), { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (editCategoryItemInitData) {
      const itemType = pluralise(category_type, 2);
      let catItem = editCategoryItemInitData[`${itemType}_by_pk`];

      dispatchCategoryItem({ type: CategoryItemReducerAction.INIT, value: catItem });

      setOriginalModifierAssignments(catItem.modifier_assignments);
      setOriginalAllergyAssignments(catItem.allergy_assignments);
    }
  }, [editCategoryItemInitData, category_type, setOriginalModifierAssignments, setOriginalAllergyAssignments, item_id]);

  const handleSave = async () => {
    setSaving(true);

    const { imageData, croppedImageData, ...rest } = categoryItem;

    const item: UnknownObject = {
      ...rest,
    };

    delete item.modifiers;
    delete item.allergies;

    const mData: UnknownObject = {
      ...item,
      image_added: !!croppedImageData,
    };
    
    if (isAdmin && organisation_id) {
      mData.organisation_id = organisation_id;
    }

    const updateCategoryItemVariables = {
      pk_columns: {
        id: item_id,
      },
      set: mData,
    };

    const editAssignedModifiers = originalModifierAssignments;
    let editedModifiers: UnknownObject[] = rest.modifiers
      .filter((mod: Modifier) => editAssignedModifiers.some((i: ModifierAssignment) => mod.id === i.modifier.id))
      .map((i: Modifier) => {
        const modifierData: UnknownObject = { ...i };
        if (isAdmin && organisation_id) {
          modifierData.organisation_id = organisation_id;
        }
        return modifierData;
      });

    const assignedModifiers = originalModifierAssignments;
    let modifierAssignments: UnknownObject[] = rest.modifiers
      .filter((mod: Modifier) => !assignedModifiers.some((i: ModifierAssignment) => mod.id === i.modifier.id))
      .map((mod: Modifier) => {
        const modifierData: UnknownObject = {
          modifier: {
            data: {
              name: mod.name,
              price: mod.price,
              tax: mod.tax,
            },
          },
        };
        
        if (isAdmin && organisation_id) {
          modifierData.modifier.data.organisation_id = organisation_id;
        }
        
        return modifierData;
      });

    const modifierAssignmentsRemove = assignedModifiers
      .filter((i: ModifierAssignment) => !rest.modifiers.some((mod: Modifier) => mod.id === i.modifier.id))
      .map((i: ModifierAssignment) => i.id);

    const assignedAllergies = originalAllergyAssignments;
    let allergyAssignments: UnknownObject[] = rest.allergies
      .filter((al: Allergy) => !assignedAllergies.some((i: AllergyAssignment) => al.id === i.allergy.id))
      .map((al: Allergy) => {
        const allergyData: UnknownObject = { allergy_id: al.id };
        if (isAdmin && organisation_id) {
          allergyData.organisation_id = organisation_id;
        }
        return allergyData;
      });

    const allergyAssignmentsRemove = assignedAllergies
      .filter((i: AllergyAssignment) => !rest.allergies.some((al: Allergy) => al.id === i.allergy.id))
      .map((i: AllergyAssignment) => i.id);

    modifierAssignments = modifierAssignments.map((i: UnknownObject) => {
      const assignmentData: UnknownObject = {
        [`${category_type}_id`]: item_id,
        ...i,
      };
      
      if (isAdmin && organisation_id) {
        assignmentData.organisation_id = organisation_id;
      }
      
      return assignmentData;
    });
    
    allergyAssignments = allergyAssignments.map((i: UnknownObject) => {
      const assignmentData: UnknownObject = {
        [`${category_type}_id`]: item_id,
        ...i,
      };
      
      if (isAdmin && organisation_id) {
        assignmentData.organisation_id = organisation_id;
      }
      
      return assignmentData;
    });

    if (category_type === 'dish') {
      try {
        await updateDish(updateCategoryItemVariables);
      } catch (error) {
        if (error?.message?.includes('organisation_id')) {
          const { organisation_id, ...dataWithoutOrgId } = mData;
          await updateDish({
            pk_columns: updateCategoryItemVariables.pk_columns,
            set: dataWithoutOrgId,
          });
        } else {
          throw error;
        }
      }
      await updateDishItemModifierAssignments(modifierAssignments, editedModifiers, modifierAssignmentsRemove);
      await updateDishItemAllergyAssignments(allergyAssignments, allergyAssignmentsRemove);
    }
    if (category_type === 'drink') {
      try {
        await updateDrink(updateCategoryItemVariables);
      } catch (error) {
        if (error?.message?.includes('organisation_id')) {
          const { organisation_id, ...dataWithoutOrgId } = mData;
          await updateDrink({
            pk_columns: updateCategoryItemVariables.pk_columns,
            set: dataWithoutOrgId,
          });
        } else {
          throw error;
        }
      }
      await updateDrinkItemModifierAssignments(modifierAssignments, editedModifiers, modifierAssignmentsRemove);
      await updateDrinkItemAllergyAssignments(allergyAssignments, allergyAssignmentsRemove);
    }

    if (croppedImageData) {
      const request = new FormData();
      const imageFile = await fetch(croppedImageData)
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], `${item_id}.jpeg`, { type: 'image/jpeg' });
        });
      request.append('file', imageFile);
      await webhookClient.postFormData('menu/images-upload', request);
    }

    setSaving(false);
    handleFinish(item_id);
  };

  return (
    <>
      <Steps
        completeLabel="Save"
        organisation_id={organisation_id!}
        categoryItem={categoryItem}
        categoryItemId={item_id}
        currency={currency}
        locale={locale}
        taxRate={taxRate}
        category_id={category_id}
        category_type={category_type}
        dispatch={dispatchCategoryItem}
        handleSave={handleSave}
      />
      {saving && <CustomBackdrop label="Saving Changes" />}
    </>
  );
};

export default withRouter(withStyles(styles)(EditCategoryItem));
