import { webhookClient } from '../../../../utils/webhookClient';
import { Menu } from '../Common';

export const getMenuQuery = async (organisationId: string, locationId: string): Promise<Menu> =>
  webhookClient.getAnon(`ordering-system/organisations/${organisationId}/locations/${locationId}`);

export const createPayment = async (cartData: string) => webhookClient.postAnon(`paypal/create-order`, cartData);

export const capturePayment = async (orderID: string, merchantId: string) => webhookClient.postAnon(`paypal/${orderID}/capture`, merchantId);

export const createQrPaymentLink = async (data: any) => webhookClient.postAnon(`ordering-system/qr-payment/create`, JSON.stringify(data));

export const getQrPaymentLinkStatus = async (id: string) => webhookClient.getAnon(`ordering-system/qr-payment/${id}/status`);

export const getQrPaymentLink = async (id: string) => webhookClient.getAnon(`ordering-system/qr-payment/${id}`);

export const processQrPayment = async (id: string, paymentId: string) => webhookClient.postAnon(`ordering-system/qr-payment/${id}/process-payment`, JSON.stringify({ paymentId }));
